import { PURCHASE_TYPES, RECURRING_TYPES } from '../../types'
import Fields from '../../../inventory/Purchase/type'
import { updateDimensions } from '../../services/mobileService'
const isMobile = updateDimensions()


const arrsMatch = (arr1, arr2) => {
    return arr1.sort().toString() === arr2.sort().toString()
}

const getOptions = (optionIds, product) => {
    let price = Math.round(product.currentPrice);
    const options = [];
    const productOptions = [];

    product.options.forEach(({ required, values, option }) => {
        values.forEach((value) => {
            if (!optionIds.includes(value.optionValueId)) return;

            value.price = Math.round(value.price);

            if (!value.pricePrefix) {
                price = value.price;
            } else {
                options.push({ ...value, required, type: option.type });
            }

            productOptions.push({
                optionId: option.optionId,
                productOptionId: value.productOptionId,
                productOptionValueId: value.value,
                name: value.label,
                type: option.type,
                value: value.price,
                option: {
                    source: option.source,
                },
                options: {
                    ...option,
                    values: values,
                },
            });
        });
    });

    return { price, options, productOptions };
};

const initialState = {
    data: [],
    navItem: new Fields().navBar,
    inputFilters: new Fields().dataFilter,
    oneData: new Fields(),
    error: [],
    count: 0,
    isOpen: isMobile ? false : localStorage.getItem('purchaseFilter') == 'true' ? true : false,
    pageNumber: {
        removed: localStorage.getItem('removedPurchasePage') || 1,
        notRemoved: localStorage.getItem('notRemovedPurchasePage') || 1
    },
    purchaseLoad: false,
    showEntries: {
        removed: localStorage.getItem('removedPurchasePageSize') || 10,
        notRemoved: localStorage.getItem('notRemovedPurchasePageSize') || 10
    },
    langTab : {
        languageId: 1,
        langTab: 0
    },
    filterTab: {
        filterTab: true,
        path: 'r/',
        removed: 'notRemoved'
    },
    initialFilter: {
        status: null,
    },
    dataFilter: {
        name: '',
        purchaseId: null,
    },
    productsFilter: {
        name: ''
    },
    isOpenProductFilter: isMobile ? false : localStorage.getItem('purchaseProductFilter') == 'true' ? true : false,
    loading: {
        list: false,
        create: false
    },
    tabPanel: '1',
    checked: false,
    ids: [],
    statistics: []
};


const purchase = ( state = initialState, action = {}) => {

    switch (action.type) {

        case  PURCHASE_TYPES.PURCHASE_CREATE_P: {

            return {
                ...state,
                loading: { ...state.loading, create: true }
            }

        }

        case  PURCHASE_TYPES.PURCHASE_CREATE_F: {

            return {
                ...state,
                loading: { ...state.loading, create: false }
            }

        }

        case  PURCHASE_TYPES.PURCHASE_CREATE_R: {

            return {
                ...state,
                loading: { ...state.loading, create: false }
            }

        }

        case  PURCHASE_TYPES.PURCHASE_UPDATE_P: {

            return {
                ...state,
                loading: { ...state.loading, create: true }
            }

        }

        case  PURCHASE_TYPES.PURCHASE_UPDATE_F: {

            return {
                ...state,
                loading: { ...state.loading, create: false }
            }

        }

        case  PURCHASE_TYPES.PURCHASE_UPDATE_R: {

            return {
                ...state,
                loading: { ...state.loading, create: false }
            }

        }

        case  PURCHASE_TYPES.PURCHASE_LIVE_DATA: {
            let data = state.data
            data.pop()
            data = [action.payload, ...data]

            return {
                ...state,
                data: data,
                count: state.count + 1
            }

        }

        case PURCHASE_TYPES.PURCHASE_PAG_F: {

            return {
                ...state,
                loading: { ...state.loading, list: false },
                data: action.payload.data.map((elem) => state.ids.includes(elem.purchaseId) ? ({ ...elem, isChecked: true, source: elem.name }) : ({ ...elem, source: elem.name, isChecked: false })),
                checked: action.payload.data.length && action.payload.data.every(elem => state.ids.includes(elem.purchaseId)),
                count: action.payload.count
            }

        }

        case PURCHASE_TYPES.GET_SUPPLIER_PRODUCT_COST_F: {
            state.oneData.purchaseProducts = state.oneData.purchaseProducts.map(product => {
                const updatedProduct = action.payload.find(updatedProduct => updatedProduct.productId === product.productId);
                if (updatedProduct) {

                    return {
                        ...product,
                        cost: updatedProduct.cost
                    };

                } else {

                    return product;

                }
            })

            return {
                ...state,
                loading: { ...state.loading, list: false }
            }

        }

        case PURCHASE_TYPES.PURCHASE_SIZE_CHANGE: {

            const showEntries = Object.assign(state.showEntries, action.payload)
        
            return {
                ...state,
                showEntries: showEntries
            }

        }

        case PURCHASE_TYPES.PURCHASE_PAGE_CHANGE: {
        
            const pageNumber = Object.assign(state.pageNumber, action.payload)

            return {
                ...state,
                pageNumber: pageNumber
            }

        }

        case PURCHASE_TYPES.PURCHASE_FILETR_TABE_CHANGE: {

            return {
                ...state,
                filterTab: action.payload,
                checked: false
            }

        }

        case PURCHASE_TYPES.PURCHASE_TAB_PANEL_CHANGE: {

            return {
                ...state,
                tabPanel: action.payload
            }

        }

        case PURCHASE_TYPES.PURCHASE_CHECKBOX_CHANGE: {
            let ids = [...state.ids]
            const { checked, multiple, key } = action.payload
            const data = state.data.map(elem => {
                if (multiple) {

                    elem['isChecked'] = checked
                    return elem

                } else {

                    state.data[key]['isChecked'] = checked
                    return elem
                    
                }
            })

            data.forEach(elem => { if (elem.isChecked)  ids.push(elem.purchaseId) })
        
            return {
                ...state,
                data: data,
                checked: !multiple ? data.every(element => element.isChecked): checked,
                ids: ids
            }

        }

        case PURCHASE_TYPES.PURCHASE_BULK_ARCIVE_F: {
        
            return { 
                ...state,
                checked: false
            }

        }

        case PURCHASE_TYPES.PURCHASE_BULK_DESTROY_F: {
        
            return { 
                ...state,
                checked: false
            }

        }

        case PURCHASE_TYPES.PURCHASE_LANGUAGE_PANEL_CHANGE: {

            return { 
                ...state,
                langTab: action.payload,
                ['initialFilter'] :  Object.assign({}, state.initialFilter, { products: "" })
            }

        }

        case PURCHASE_TYPES.PURCHASE_CREATE_R: {

            return { 
                ...state,
                error: action.payload.response
            }

        }

        case PURCHASE_TYPES.PURCHASE_GET_ONE_F: {

            action.payload.purchaseProducts = action.payload.purchaseProducts.map((item) =>({
                ...item,
                productName: item.product.descriptions[0].name,
                quantity: item.orderedQty,
                options: item.purchaseProductOption.map(option => ({
                    ...option, 
                    optionId: option.option.optionId,
                    options: item.product.productOptions.find((elOption) => elOption.optionId == option.option.optionId)
                })),
                activeOptions: item.purchaseProductOption.map(option => option.productOptionValueId),
            }))

            return { 
                ...state,
                oneData: action.payload,
                navItem: new Fields().navBar,
                error: []
            }
            
        }

        case PURCHASE_TYPES.PURCHASE_REQUIRED_VALIDATION_R: {
            state.navItem = new Fields().navBar
            const tabPanel  = !action.payload.tab ? state.tabPanel : action.payload.errorMsg.some(element => element.tab === state.tabPanel) ? state.tabPanel : action.payload.errorMsg[0].tab
            action.payload.errorMsg.forEach(elem => {
                state.navItem[parseInt(elem.tab) - 1].count++
                state.navItem[parseInt(elem.tab) - 1].color = 'red'
                state.navItem[parseInt(elem.tab) - 1].inputs.map(val => {
                    if (val.field === elem.name) {
                        val['color'] = 'red'
                    }
                })
            })

            return { 
                ...state,
                error: action.payload.errorMsg,
                tabPanel: tabPanel
            }

        }

        case PURCHASE_TYPES.PURCHASE_REQUIRED_VALIDATION_F: {

            return { 
                ...state,
                error: [],
                navItem: new Fields().navBar
            }

        }

        case PURCHASE_TYPES.NEW_PURCHASE: {

            return { 
                ...state,
                oneData: new Fields(),
                navItem: new Fields().navBar,
                error: []
            }

        }

        case PURCHASE_TYPES.PURCHASE_DATA_FILTER: {

            return { 
                ...state,
                dataFilter: { [action.payload['key']]: action.payload['value'] },
                error: []
            }

        }

        case PURCHASE_TYPES.PURCHASE_DATA_FILTER_SEARCH: {

            return { 
                ...state,
                initialFilter: Object.assign({}, state.initialFilter, { [action.payload['key']]: action.payload['value'] }),
                error: []
            }

        }

        case PURCHASE_TYPES.PURCHASE_DELETE_F: {
            const id = action.payload;

            return {
                ...state,
                data: state.data.filter(item => item.purchaseId !== id)
            }

        }

        case PURCHASE_TYPES.PURCHASE_FILTER_IS_OPEN: {
            localStorage.setItem('purchaseFilter', !state.isOpen)

            return {
                ...state,
                isOpen: !state.isOpen
            }

        }

        case PURCHASE_TYPES.PURCHASE_PRODUCTS_FILTER_IS_OPEN: {
            localStorage.setItem('purchaseProductFilter', !state.isOpenProductFilter)

            return {
                ...state,
                isOpenProductFilter: !state.isOpenProductFilter
            }

        }

        case PURCHASE_TYPES.PURCHASE_PRODUCTS_DATA_FILTER: {

            return { 
                ...state,
                productsFilter: {
                    name: state.productsFilter.name,
                    [action.payload['key']]: action.payload['value'] 
                },
                error: []
            }

        }

        case PURCHASE_TYPES.PURCHASE_PRODUCTS_DATA_FILTER_ALL_SEARCH_RESET: {

            return { 
                ...state,
                productsFilter: Object.assign({}, state.productsFilter, { 
                    name: ''
                }),
                error: []
            }

        }

        case PURCHASE_TYPES.PURCHASE_PRODUCTS_DATA_FILTER_SEARCH: {

            return { 
                ...state,
                productsFilter: Object.assign({}, state.productsFilter, { [action.payload['key']]: action.payload['value'] }),
                error: []
            }

        }

        case PURCHASE_TYPES.PURCHASE_DATA_FILTER_SEARCH_RESET: {
            const { key } = action.payload

            return {
                ...state,
                [key]:  {
                    name: ''
                }
            }

        }

        case PURCHASE_TYPES.PURCHASE_DATA_FILTER_ALL_SEARCH_RESET: {

            return { 
                ...state,
                initialFilter: Object.assign({}, state.initialFilter, { 
                    name: ''
                }),
                error: []
            }

        }

        case PURCHASE_TYPES.PURCHASE_ADD_PRODUCT: {
            const value = action.payload;

            const index = state.oneData.purchaseProducts.findIndex((e) => e.productId == value.productId ? arrsMatch(e.activeOptions, value.activeOptions) : ( e.productId == value.productId ))
            
            if (index === -1) {
                // If product doesn't exist, add it to the beginning of the array
                let { productOptions } = getOptions(value.activeOptions, value);
                state.oneData.purchaseProducts.unshift({
                    productName: value.name,
                    productId: value.key,
                    orderedQty: value.quantity,
                    receivedQty: 0,
                    cost: 0,
                    tax: 0,
                    quantity: value.quantity,
                    product: value,
                    options: productOptions,
                    activeOptions: value.activeOptions,
                    purchaseProductOption: productOptions,
                    price: value.price,
                    prevPrice: value.prevPrice
                });
        
            } else if (!value.quantity) {
                // If quantity is zero, remove the product from the array using the index
                state.oneData.purchaseProducts = state.oneData.purchaseProducts.filter((_, i) => i !== index);
        
            } else {
                // If product exists and quantity is provided, update the product at the found index
                state.oneData.purchaseProducts = state.oneData.purchaseProducts.map((item, i) =>
                    i === index ? { ...item, orderedQty: value.quantity, quantity: value.quantity } : item
                );
            }
        
            return { 
                ...state
            };

        }

        case PURCHASE_TYPES.PURCHASE_REMOVE_PRODUCT: {
            const value = action.payload;

            const index = state.oneData.purchaseProducts.findIndex((e) => e.productId == value.productId ? arrsMatch(e.activeOptions, value.activeOptions) : ( e.productId == value.productId ))
            state.oneData.purchaseProducts.splice(index, 1)      

            return { 
                ...state
            }

        }

        case PURCHASE_TYPES.PURCHASE_PRODUCTS_PAG_F: {

            return {
                ...state
            }

        }

        case PURCHASE_TYPES.CHANGE_PURCHASE_PRODUCT_VALUES: {
            
            const value = action.payload;
            value.quantity = value.orderedQty;
            
            const index = state.oneData.purchaseProducts.findIndex((e) => e.productId == value.productId ? arrsMatch(e.activeOptions, value.activeOptions) : ( e.productId == value.productId ))

            return {
                ...state,
                    oneData: {
                    ...state.oneData,
                    purchaseProducts: state.oneData.purchaseProducts.map((elem, i) =>
                        i === index ? { ...elem, ...value } : elem
                    )
                }
            }

        }

        case PURCHASE_TYPES.IMPORT_PURCHASE_PRODUCTS: {

            return {
                ...state,
                oneData: {
                    ...state.oneData,
                    purchaseProducts: [
                        ...state.oneData.purchaseProducts, 
                        ...action.payload
                    ]
                }
            }

        }
    
        default:
            return state;
    }
}

export {
    purchase
}