const PURCHASE_PAG_F = 'PURCHASE_PAG_L_F';
const PURCHASE_PAG_R = 'PURCHASE_PAG_L_R';
const PURCHASE_SIZE_CHANGE = 'PURCHASE_SIZE_CHANGE'
const PURCHASE_PAGE_CHANGE = 'PURCHASE_PAGE_CHANGE'
const PURCHASE_FILETR_TABE_CHANGE = 'PURCHASE_FILETR_TABE_CHANGE'
const PURCHASE_UPDATE_P = 'PURCHASE_UPDATE_P'
const PURCHASE_UPDATE_F = 'PURCHASE_UPDATE_F'
const PURCHASE_UPDATE_R = 'PURCHASE_UPDATE_R'
const PURCHASE_CHECKBOX_CHANGE = 'PURCHASE_CHECKBOX_CHANGE'
const PURCHASE_BULK_ARCIVE_F = 'PURCHASE_BULK_ARCIVE_F'
const PURCHASE_BULK_ARCIVE_R = 'PURCHASE_BULK_ARCIVE_R'
const PURCHASE_BULK_DESTROY_F = 'PURCHASE_BULK_DESTROY_F'
const PURCHASE_BULK_DESTROY_R = 'PURCHASE_BULK_DESTROY_R'
const PURCHASE_TAB_PANEL_CHANGE = 'PURCHASE_TAB_PANEL_CHANGE'
const PURCHASE_CREATE_P = 'PURCHASE_CREATE_P'
const PURCHASE_CREATE_F = 'PURCHASE_CREATE_F'
const PURCHASE_CREATE_R = 'PURCHASE_CREATE_R'
const PURCHASE_GET_ONE_F = 'PURCHASE_GET_ONE_F'
const PURCHASE_GET_ONE_R = 'PURCHASE_GET_ONE_R'
const PURCHASE_REQUIRED_VALIDATION_F = 'PURCHASE_REQUIRED_VALIDATION_F'
const PURCHASE_REQUIRED_VALIDATION_R = 'PURCHASE_REQUIRED_VALIDATION_R'
const NEW_PURCHASE = 'NEW_PURCHASE'
const PURCHASE_DATA_FILTER = 'PURCHASE_DATA_FILTER'
const PURCHASE_DELETE_F = 'PURCHASE_DELETE_F'
const PURCHASE_DELETE_R = 'PURCHASE_DELETE_R'
const PURCHASE_LIVE_DATA = 'PURCHASE_LIVE_DATA'
const PURCHASE_DATA_FILTER_SEARCH = 'PURCHASE_DATA_FILTER_SEARCH'
const PURCHASE_FILTER_IS_OPEN = 'PURCHASE_FILTER_IS_OPEN'
const PURCHASE_LANGUAGE_PANEL_CHANGE = 'PURCHASE_LANGUAGE_PANEL_CHANGE'
const PURCHASE_DATA_FILTER_SEARCH_RESET = 'PURCHASE_DATA_FILTER_SEARCH_RESET'
const PURCHASE_DATA_FILTER_ALL_SEARCH_RESET = 'PURCHASE_DATA_FILTER_ALL_SEARCH_RESET'
const PURCHASE_ADD_PRODUCT = 'PURCHASE_ADD_PRODUCT'
const PURCHASE_REMOVE_PRODUCT = 'PURCHASE_REMOVE_PRODUCT'
const GET_SUPPLIER_PRODUCT_COST_P = 'GET_SUPPLIER_PRODUCT_COST_P'
const GET_SUPPLIER_PRODUCT_COST_F = 'GET_SUPPLIER_PRODUCT_COST_F'
const GET_SUPPLIER_PRODUCT_COST_R = 'GET_SUPPLIER_PRODUCT_COST_R'
const PURCHASE_PRODUCTS_PAG_P = 'PURCHASE_PRODUCTS_PAG_P'
const PURCHASE_PRODUCTS_PAG_F = 'PURCHASE_PRODUCTS_PAG_F'
const PURCHASE_PRODUCTS_PAG_R = 'PURCHASE_PRODUCTS_PAG_R'
const PURCHASE_PRODUCTS_DATA_FILTER_ALL_SEARCH_RESET = 'PURCHASE_PRODUCTS_DATA_FILTER_ALL_SEARCH_RESET'
const PURCHASE_PRODUCTS_DATA_FILTER = 'PURCHASE_PRODUCTS_DATA_FILTER'
const PURCHASE_PRODUCTS_FILTER_IS_OPEN = 'PURCHASE_PRODUCTS_FILTER_IS_OPEN'
const PURCHASE_PRODUCTS_DATA_FILTER_SEARCH = 'PURCHASE_PRODUCTS_DATA_FILTER_SEARCH'
const IMPORT_PURCHASE_PRODUCTS = 'IMPORT_PURCHASE_PRODUCTS'
const CHANGE_PURCHASE_PRODUCT_VALUES = 'CHANGE_PURCHASE_PRODUCT_VALUES'

export {
    PURCHASE_PAG_F,
    PURCHASE_PAG_R,
    PURCHASE_SIZE_CHANGE,
    PURCHASE_PAGE_CHANGE,
    PURCHASE_FILETR_TABE_CHANGE,
    PURCHASE_UPDATE_P,
    PURCHASE_UPDATE_F,
    PURCHASE_UPDATE_R,
    PURCHASE_CHECKBOX_CHANGE,
    PURCHASE_BULK_ARCIVE_F,
    PURCHASE_BULK_ARCIVE_R,
    PURCHASE_BULK_DESTROY_F,
    PURCHASE_BULK_DESTROY_R,
    PURCHASE_TAB_PANEL_CHANGE,
    PURCHASE_CREATE_P,
    PURCHASE_CREATE_F,
    PURCHASE_CREATE_R,
    PURCHASE_GET_ONE_F,
    PURCHASE_GET_ONE_R,
    PURCHASE_REQUIRED_VALIDATION_F,
    PURCHASE_REQUIRED_VALIDATION_R,
    NEW_PURCHASE,
    PURCHASE_DATA_FILTER,
    PURCHASE_DELETE_F,
    PURCHASE_DELETE_R,
    PURCHASE_LIVE_DATA,
    PURCHASE_DATA_FILTER_SEARCH,
    PURCHASE_FILTER_IS_OPEN,
    PURCHASE_LANGUAGE_PANEL_CHANGE,
    PURCHASE_DATA_FILTER_SEARCH_RESET,
    PURCHASE_DATA_FILTER_ALL_SEARCH_RESET,
    PURCHASE_ADD_PRODUCT,
    PURCHASE_REMOVE_PRODUCT,
    GET_SUPPLIER_PRODUCT_COST_P,
    GET_SUPPLIER_PRODUCT_COST_F,
    GET_SUPPLIER_PRODUCT_COST_R,
    PURCHASE_PRODUCTS_PAG_P,
    PURCHASE_PRODUCTS_PAG_F,
    PURCHASE_PRODUCTS_PAG_R,
    PURCHASE_PRODUCTS_DATA_FILTER_ALL_SEARCH_RESET,
    PURCHASE_PRODUCTS_DATA_FILTER,
    PURCHASE_PRODUCTS_FILTER_IS_OPEN,
    PURCHASE_PRODUCTS_DATA_FILTER_SEARCH,
    IMPORT_PURCHASE_PRODUCTS,
    CHANGE_PURCHASE_PRODUCT_VALUES
}