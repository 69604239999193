import  { EQUIPMENT_TYPES } from '../../types'
import config from '../../../../config/app'
import axios from 'axios'
import qs from 'qs'
import { Notification } from "element-react";

 class EquipmentActions {
  constructor(_props) {
    this.api = config['STORE']
    this.rest  = 'c/equipment'
    this.arcive = '/b/'
  }

    pagination = (jwt, params, page, count, query) => {
          return async dispatch => {
            try {
              const searchString = qs.stringify(query)
              const response = await axios.get(
                this.api + this.rest + `/${params}` + page + '/' + count + '?' + searchString,
                {
                    headers: {
                      "Content-Type": "application/json",
                      'Authorization': `Bearer ${jwt}`
                    }
                })
                
                response.data.data = response.data.data.map((elem) => ({ 
                  ...elem, type: elem.type?.name || '', 
                  condition: elem.condition?.name || '' })
                )

                dispatch({
                    type: EQUIPMENT_TYPES.EQUIPMENT_PAG_F,
                    payload: response.data
                })
            } catch (e) {
                dispatch({
                    type: EQUIPMENT_TYPES.EQUIPMENT_PAG_R,
                    payload: e
                })
            }
        }
    }

    update = (jwt, body, Type, history) => {
      return async dispatch => {
        try {
            dispatch({
              type: EQUIPMENT_TYPES[Type + '_UPDATE_P']
            })

            await axios.put(
            this.api + this.rest + `/${body.equipmentId}`,
            body,
            {
              headers: 
                {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
            })

            Notification.warning({
              title: 'Equipment',
              message: 'Your equipment has been succesfuly updated!'
            }, 20)

            dispatch({
                type: EQUIPMENT_TYPES[Type + '_UPDATE_F'],
                payload: body
            })

            history.push('/business/equipment')
            
        } catch (e) {

            if (e.response.status === 422) {
              if (Array.isArray(e.response.data)) {
                  e.response.data.forEach(err => {
                      Notification.error({
                          title: err.path,
                          message: err.msg
                      }, 20)
                  })
              } else {
                  Notification.error({
                      title: e.response.data.path,
                      message: e.response.data.msg
                  }, 20)
              }
            }

            dispatch({
                type: EQUIPMENT_TYPES[Type + '_UPDATE_R'],
                payload: e
            })

          }
        }
    }

    destroy = (jwt, id, Type) => {
      return async dispatch => {
        try {
          await axios.delete(
            this.api + this.rest + `/${id}`,
          {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
          }

          )
            dispatch({
                type: EQUIPMENT_TYPES[Type + '_DELETE_F'],
                payload: id
            })
        } catch (e) {
            dispatch({
                type: EQUIPMENT_TYPES[Type + '_DELETE_R'],
                payload: e
            })
          }
        }
    }

    getOne = (jwt, id, Type) => {
      return async dispatch => {
        try {
          const response = await axios.get(
            this.api + this.rest + `/${id}`,
          {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
          }

          )
            dispatch({
                type: EQUIPMENT_TYPES[Type + '_GET_ONE_F'],
                payload: response.data
            })
        } catch (e) {
            dispatch({
                type: EQUIPMENT_TYPES[Type + '_GET_ONE_R'],
                payload: e
            })
          }
        }
    }

    showWorkedHours = (jwt, id, query) => {
      return async dispatch => {
        try {

          dispatch({
              type: EQUIPMENT_TYPES.FETCH_EQUIPMENT_WORKED_HOURS_P
          })

          const searchString = qs.stringify(query)
          
          const response = await axios.get(
            this.api + this.rest + `/worked_hours/${id}?${searchString}`,
              {
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`
                  }
              }
            )

            dispatch({
                type: EQUIPMENT_TYPES.FETCH_EQUIPMENT_WORKED_HOURS_F,
                payload: response.data
            })
        } catch (e) {
            dispatch({
                type: EQUIPMENT_TYPES.FETCH_EQUIPMENT_WORKED_HOURS_R,
                payload: e
            })
          }
        }
    }

    listWorkedHours = (jwt, id, query) => {
      return async dispatch => {
        try {

          dispatch({
              type: EQUIPMENT_TYPES.FETCH_EQUIPMENT_WORKED_HOURS_LIST_P
          })
          
          const response = await axios.get(
            this.api + this.rest + `/worked_hours/${id}/${query.page}/${query.count}`,
              {
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`
                  }
              }
            )

            dispatch({
                type: EQUIPMENT_TYPES.FETCH_EQUIPMENT_WORKED_HOURS_LIST_F,
                payload: response.data
            })
        } catch (e) {
            dispatch({
                type: EQUIPMENT_TYPES.FETCH_EQUIPMENT_WORKED_HOURS_LIST_R,
                payload: e
            })
          }
        }
    }

    /**
     * @name bulk
     * @param elem
     * @returns {function(*)}
     */
      bulk = (ids, jwt, params, otherDispatchParams) => {
        return async dispatch => {
          try {
             await axios.post(
              this.api + this.rest + this.arcive + params,
              ids,
              {
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`
                  }
              }
            )
              dispatch({
                  type: EQUIPMENT_TYPES.EQUIPMENT_BULK_ARCIVE_F,
                  payload: ids
              })

              dispatch(this.pagination(...otherDispatchParams))
              
          } catch (e) {

              dispatch({
                  type: EQUIPMENT_TYPES.EQUIPMENT_BULK_ARCIVE_R,
                  payload: e
              })

            }
          }
      }


    /**
     * @name bulkDestroy
     * @param elem
     * @returns {function(*)}
     */
    bulkDestroy = (ids, jwt, _params, otherDispatchParams) => {
      return async dispatch => {
        try {
           await axios.post(
            this.api + this.rest + this.arcive + 'a',
            ids,
            {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
            }
          )
            dispatch({
                type: EQUIPMENT_TYPES.EQUIPMENT_BULK_DESTROY_F,
                payload: ids
            })

            dispatch(this.pagination(...otherDispatchParams))
            
        } catch (e) {

            dispatch({
                type: EQUIPMENT_TYPES.EQUIPMENT_BULK_DESTROY_R,
                payload: e
            })

          }
        }
    }

      create = (jwt, body, Type, history) => {
        return async dispatch => {

            dispatch({
              type: EQUIPMENT_TYPES[Type + '_CREATE_P']
            })
            
            await axios.post(
                this.api + this.rest,
                body,
                {
                    headers: {
                      "Content-Type": "application/json",
                      'Authorization': `Bearer ${jwt}`
                    }
                }
              )
              .then(response => {

                Notification.success({
                  title: 'Equipment',
                  message: 'Your equipment has been succesfuly create!'
                }, 20)

                dispatch({
                    type: EQUIPMENT_TYPES[Type + '_CREATE_F'],
                    payload: response
                })

                history.push('/business/equipment')

              })
              .catch(e => {
                if (e.response.status === 422) {
                  if (Array.isArray(e.response.data)) {
                      e.response.data.forEach(err => {
                          Notification.error({
                              title: err.path,
                              message: err.msg
                          }, 20)
                      })
                  } else {
                      Notification.error({
                          title: e.response.data.path,
                          message: e.response.data.msg
                      }, 20)
                  }
                }

                dispatch({
                      type: EQUIPMENT_TYPES[Type + '_CREATE_R'],
                      payload: e
                  })

              })
        }
      }

      fetchConditionsList = (jwt) => {
          return async dispatch => {
            try {

              dispatch({
                type: EQUIPMENT_TYPES.FETCH_EQUIPMENT_CONDITIONS_LIST_P
              })

              const response = await axios.get(
                this.api + this.rest + '/conditions',
                {
                    headers: {
                      "Content-Type": "application/json",
                      'Authorization': `Bearer ${jwt}`
                    }
                })

                dispatch({
                    type: EQUIPMENT_TYPES.FETCH_EQUIPMENT_CONDITIONS_LIST_F,
                    payload: response.data
                })
            } catch (e) {
                dispatch({
                    type: EQUIPMENT_TYPES.FETCH_EQUIPMENT_CONDITIONS_LIST_R,
                    payload: e
                })
            }
        }
      }

      fetchTypesList = (jwt) => {
          return async dispatch => {
            try {

              dispatch({
                type: EQUIPMENT_TYPES.FETCH_EQUIPMENT_TYPES_LIST_P
              })

              const response = await axios.get(
                this.api + this.rest + '/types',
                {
                    headers: {
                      "Content-Type": "application/json",
                      'Authorization': `Bearer ${jwt}`
                    }
                })
                
                dispatch({
                    type: EQUIPMENT_TYPES.FETCH_EQUIPMENT_TYPES_LIST_F,
                    payload: response.data
                })
            } catch (e) {
                dispatch({
                    type: EQUIPMENT_TYPES.FETCH_EQUIPMENT_TYPES_LIST_R,
                    payload: e
                })
            }
        }
      }
  }




export default new EquipmentActions()