export default class {
    constructor () {
        this.conditionId = 1
        this.typeId = 1,
        this.name = ''
        this.model = ''
        this.images = [],
        this.price = null,
        this.quantity = 1, 
        this.attachedCount = 0
    }
}