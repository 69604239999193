
const GET_ALL_ORDER_LIST = 'GET_ALL_ORDER_LIST';
const GET_NEW_ORDER_LIST = 'GET_NEW_ORDER_LIST';
const GET_ALL_REMOVED_ORDER_LIST = 'GET_ALL_REMOVED_ORDER_LIST';
const GET_ALL_DONE_ORDER_LIST = 'GET_ALL_DONE_ORDER_LIST';
const SELECT_ORDER_LIST_ITEMS = 'SELECT_ORDER_LIST_ITEMS';
const GET_ALL_ORDERS = 'GET_ALL_ORDERS';
const GET_PAGINATION_ORDERS = 'GET_PAGINATION_ORDERS';
const GET_NEW_SOCKET_ORDER_LIST = 'GET_NEW_SOCKET_ORDER_LIST';
const CHANGE_STATUS_ORDERS = 'CHANGE_STATUS_ORDERS';
const GET_ORDER_STATUS_LIST = 'GET_ORDER_STATUS_LIST';
const GET_PAGINATION_ORDERS_PG_P = 'GET_PAGINATION_ORDERS_PG_P';
const GET_PAGINATION_ORDERS_PG_F = 'GET_PAGINATION_ORDERS_PG_F';
const GET_PAGINATION_ORDERS_PG_R = 'GET_PAGINATION_ORDERS_PG_R';
const ORDER_FILTER_IS_OPEN = 'ORDER_FILTER_IS_OPEN'
const ORDER_DATA_FILTER = 'ORDER_DATA_FILTER'
const ORDER_DATA_FILTER_SEARCH = 'ORDER_DATA_FILTER_SEARCH'
const ORDER_DATA_MIN_MAX_F = 'ORDER_DATA_MIN_MAX_F'
const DESTROY_ORDER = 'DESTROY_ORDER'
const ORDER_DATA_FILTER_ALL_SEARCH_RESET = 'ORDER_DATA_FILTER_ALL_SEARCH_RESET'
const GET_ORDER_STATISTICS = 'GET_ORDER_STATISTICS'
const CHANGE_FRANCHISE_ORDERS = 'CHANGE_FRANCHISE_ORDERS'
const CHANGE_ORDER_PAYMENT_METHOD = 'CHANGE_ORDER_PAYMENT_METHOD'
const GET_ORDER_P = 'GET_ORDER_P'
const GET_ORDER_F = 'GET_ORDER_F'
const GET_ORDER_R = 'GET_ORDER_R'
const ORDER_UPDATE_P = 'ORDER_UPDATE_P'
const ORDER_UPDATE_F = 'ORDER_UPDATE_F'
const ORDER_UPDATE_R = 'ORDER_UPDATE_R'
const ORDER_CREATE_P = 'ORDER_CREATE_P'
const ORDER_CREATE_F = 'ORDER_CREATE_F'
const ORDER_CREATE_R = 'ORDER_CREATE_R'
const UPLOAD_LICENSE_FILES_P = 'UPLOAD_LICENSE_FILES_P'
const UPLOAD_LICENSE_FILES_F = 'UPLOAD_LICENSE_FILES_F'
const UPLOAD_LICENSE_FILES_R = 'UPLOAD_LICENSE_FILES_R'
const GET_ORDER_LOCATIONS_P = 'GET_ORDER_LOCATIONS_P'
const GET_ORDER_LOCATIONS_F = 'GET_ORDER_LOCATIONS_F'
const GET_ORDER_LOCATIONS_R = 'GET_ORDER_LOCATIONS_R'
const GET_ORDER_CATEGORIES = 'GET_ORDER_CATEGORIES'
const GET_ORDER_TYPES = 'GET_ORDER_TYPES'
const ORDER_CHOOSE_STAFF_P = 'ORDER_CHOOSE_STAFF_P'
const ORDER_CHOOSE_STAFF_F = 'ORDER_CHOOSE_STAFF_F'
const ORDER_CHOOSE_STAFF_R = 'ORDER_CHOOSE_STAFF_R'
const ORDER_PRODUCT_LIST_P = 'ORDER_PRODUCT_LIST_P'
const ORDER_PRODUCT_LIST_F = 'ORDER_PRODUCT_LIST_F'
const ORDER_PRODUCT_LIST_R = 'ORDER_PRODUCT_LIST_R'
const GET_ORDER_CANCELLATION_REASONS_LIST = 'GET_ORDER_CANCELLATION_REASONS_LIST'
const EXPORT_ORDERS_P = 'EXPORT_ORDERS_P'
const EXPORT_ORDERS_F = 'EXPORT_ORDERS_F'
const EXPORT_ORDERS_R = 'EXPORT_ORDERS_R'

export {
  GET_ALL_DONE_ORDER_LIST,
  GET_ALL_ORDER_LIST,
  GET_NEW_ORDER_LIST,
  GET_ALL_REMOVED_ORDER_LIST,
  SELECT_ORDER_LIST_ITEMS,
  GET_ALL_ORDERS,
  GET_NEW_SOCKET_ORDER_LIST,
  GET_PAGINATION_ORDERS,
  CHANGE_STATUS_ORDERS,
  GET_ORDER_STATUS_LIST,
  GET_PAGINATION_ORDERS_PG_P,
  GET_PAGINATION_ORDERS_PG_F,
  GET_PAGINATION_ORDERS_PG_R,
  ORDER_FILTER_IS_OPEN,
  ORDER_DATA_FILTER,
  ORDER_DATA_FILTER_SEARCH,
  ORDER_DATA_MIN_MAX_F,
  DESTROY_ORDER,
  ORDER_DATA_FILTER_ALL_SEARCH_RESET,
  GET_ORDER_STATISTICS,
  CHANGE_FRANCHISE_ORDERS,
  CHANGE_ORDER_PAYMENT_METHOD,
  GET_ORDER_P,
  GET_ORDER_F,
  GET_ORDER_R,
  ORDER_UPDATE_P,
  ORDER_UPDATE_F,
  ORDER_UPDATE_R,
  ORDER_CREATE_P,
  ORDER_CREATE_F,
  ORDER_CREATE_R,
  UPLOAD_LICENSE_FILES_P,
  UPLOAD_LICENSE_FILES_F,
  UPLOAD_LICENSE_FILES_R,
  GET_ORDER_LOCATIONS_P,
  GET_ORDER_LOCATIONS_F,
  GET_ORDER_LOCATIONS_R,
  GET_ORDER_CATEGORIES,
  GET_ORDER_TYPES,
  ORDER_CHOOSE_STAFF_P,
  ORDER_CHOOSE_STAFF_F,
  ORDER_CHOOSE_STAFF_R,
  ORDER_PRODUCT_LIST_P,
  ORDER_PRODUCT_LIST_F,
  ORDER_PRODUCT_LIST_R,
  GET_ORDER_CANCELLATION_REASONS_LIST,
  EXPORT_ORDERS_P,
  EXPORT_ORDERS_F,
  EXPORT_ORDERS_R
}
