const CUSTOMER_PAG_F = 'CUSTOMER_PAG_F';
const CUSTOMER_PAG_R = 'CUSTOMER_PAG_R';
const CUSTOMER_SIZE_CHANGE = 'CUSTOMER_SIZE_CHANGE'
const CUSTOMER_PAGE_CHANGE = 'CUSTOMER_PAGE_CHANGE'
const CUSTOMER_FILETR_TABE_CHANGE = 'CUSTOMER_FILETR_TABE_CHANGE'
const CUSTOMER_UPDATE_F = 'CUSTOMER_UPDATE_F'
const CUSTOMER_UPDATE_R = 'CUSTOMER_UPDATE_R'
const CUSTOMER_CHECKBOX_CHANGE = 'CUSTOMER_CHECKBOX_CHANGE'
const CUSTOMER_BULK_ARCIVE_F = 'CUSTOMER_BULK_ARCIVE_F'
const CUSTOMER_BULK_ARCIVE_R = 'CUSTOMER_BULK_ARCIVE_R'
const CUSTOMER_BULK_DESTROY_F = 'CUSTOMER_BULK_DESTROY_F'
const CUSTOMER_BULK_DESTROY_R = 'CUSTOMER_BULK_DESTROY_R'
const CUSTOMER_TAB_PANEL_CHANGE = 'CUSTOMER_TAB_PANEL_CHANGE'
const CUSTOMER_CREATE_F = 'CUSTOMER_CREATE_F'
const CUSTOMER_CREATE_R = 'CUSTOMER_CREATE_R'
const CUSTOMER_GET_ONE_F = 'CUSTOMER_GET_ONE_F'
const CUSTOMER_GET_ONE_R = 'CUSTOMER_GET_ONE_R'
const CUSTOMER_REQUIRED_VALIDATION_F = 'CUSTOMER_REQUIRED_VALIDATION_F'
const CUSTOMER_REQUIRED_VALIDATION_R = 'CUSTOMER_REQUIRED_VALIDATION_R'
const NEW_CUSTOMER = 'NEW_CUSTOMER'
const CUSTOMER_DATA_FILTER = 'CUSTOMER_DATA_FILTER'
const CUSTOMER_DELETE_F = 'CUSTOMER_DELETE_F'
const CUSTOMER_FILTER_IS_OPEN = 'CUSTOMER_FILTER_IS_OPEN'
const CUSTOMER_DATA_FILTER_SEARCH = 'CUSTOMER_DATA_FILTER_SEARCH'
const CUSTOMER_DATA_MIN_MAX_F = 'CUSTOMER_DATA_MIN_MAX_F'
const CUSTOMER_SELECT_FILTER_TYPES = 'CUSTOMER_SELECT_FILTER_TYPES'
const CUSTOMER_LANGUAGE_PANEL_CHANGE = 'CUSTOMER_LANGUAGE_PANEL_CHANGE'
const CUSTOMER_GET_ORDER = 'CUSTOMER_LANGUAGE_GET_ORDER'
const CUSTOMER_DATA_FILTER_ALL_SEARCH_RESET = 'CUSTOMER_DATA_FILTER_ALL_SEARCH_RESET'
const FETCH_CUSTOMER_SEE_ALL_P = 'FETCH_CUSTOMER_SEE_ALL_P'
const FETCH_CUSTOMER_SEE_ALL_F = 'FETCH_CUSTOMER_SEE_ALL_F'
const FETCH_CUSTOMER_SEE_ALL_R = 'FETCH_CUSTOMER_SEE_ALL_R'
const FETCH_CUSTOMER_ORDERS_P = 'FETCH_CUSTOMER_ORDERS_P'
const FETCH_CUSTOMER_ORDERS_F = 'FETCH_CUSTOMER_ORDERS_F'
const FETCH_CUSTOMER_ORDERS_R = 'FETCH_CUSTOMER_ORDERS_R' 
const FETCH_CUSTOMER_REVIEWS_P = 'FETCH_CUSTOMER_REVIEWS_P'
const FETCH_CUSTOMER_REVIEWS_F = 'FETCH_CUSTOMER_REVIEWS_F'
const FETCH_CUSTOMER_REVIEWS_R = 'FETCH_CUSTOMER_REVIEWS_R' 
const FETCH_CUSTOMER_WISHLISTS_BRAND_P = 'FETCH_CUSTOMER_WISHLISTS_BRAND_P'
const FETCH_CUSTOMER_WISHLISTS_BRAND_F = 'FETCH_CUSTOMER_WISHLISTS_BRAND_F'
const FETCH_CUSTOMER_WISHLISTS_BRAND_R = 'FETCH_CUSTOMER_WISHLISTS_BRAND_R'
const FETCH_CUSTOMER_WISHLISTS_PRODUCT_P = 'FETCH_CUSTOMER_WISHLISTS_PRODUCT_P'
const FETCH_CUSTOMER_WISHLISTS_PRODUCT_F = 'FETCH_CUSTOMER_WISHLISTS_PRODUCT_F'
const FETCH_CUSTOMER_WISHLISTS_PRODUCT_R = 'FETCH_CUSTOMER_WISHLISTS_PRODUCT_R'
const FETCH_CUSTOMER_CART_P = 'FETCH_CUSTOMER_CART_P'
const FETCH_CUSTOMER_CART_F = 'FETCH_CUSTOMER_CART_F'
const FETCH_CUSTOMER_CART_R = 'FETCH_CUSTOMER_CART_R'
const IMPORT_CUSTOMERS_P = 'IMPORT_CUSTOMERS_P'
const IMPORT_CUSTOMERS_F = 'IMPORT_CUSTOMERS_F'
const IMPORT_CUSTOMERS_R = 'IMPORT_CUSTOMERS_R'
const CUSTOMER_STATISTICS_P = 'CUSTOMER_STATISTICS_P'
const CUSTOMER_STATISTICS_F = 'CUSTOMER_STATISTICS_F'
const CUSTOMER_STATISTICS_R = 'CUSTOMER_STATISTICS_R'    
const CUSTOMER_US_PHONE_P = 'CUSTOMER_US_PHONE_P'
const CUSTOMER_US_PHONE_F = 'CUSTOMER_US_PHONE_F'
const CUSTOMER_US_PHONE_R = 'CUSTOMER_US_PHONE_R'

export {
    CUSTOMER_PAG_R,
    CUSTOMER_PAG_F,
    CUSTOMER_SIZE_CHANGE,
    CUSTOMER_PAGE_CHANGE,
    CUSTOMER_FILETR_TABE_CHANGE,
    CUSTOMER_UPDATE_F,
    CUSTOMER_UPDATE_R,
    CUSTOMER_CHECKBOX_CHANGE,
    CUSTOMER_BULK_ARCIVE_F,
    CUSTOMER_BULK_ARCIVE_R,
    CUSTOMER_BULK_DESTROY_R,
    CUSTOMER_BULK_DESTROY_F,
    CUSTOMER_TAB_PANEL_CHANGE,
    CUSTOMER_CREATE_F,
    CUSTOMER_CREATE_R,
    CUSTOMER_GET_ONE_F,
    CUSTOMER_GET_ONE_R,
    CUSTOMER_REQUIRED_VALIDATION_F,
    CUSTOMER_REQUIRED_VALIDATION_R,
    NEW_CUSTOMER,
    CUSTOMER_DATA_FILTER,
    CUSTOMER_DELETE_F,
    CUSTOMER_FILTER_IS_OPEN,
    CUSTOMER_DATA_FILTER_SEARCH,
    CUSTOMER_DATA_MIN_MAX_F,
    CUSTOMER_SELECT_FILTER_TYPES,
    CUSTOMER_LANGUAGE_PANEL_CHANGE,
    CUSTOMER_GET_ORDER,
    CUSTOMER_DATA_FILTER_ALL_SEARCH_RESET,
    FETCH_CUSTOMER_SEE_ALL_P,
    FETCH_CUSTOMER_SEE_ALL_F,
    FETCH_CUSTOMER_SEE_ALL_R,
    FETCH_CUSTOMER_ORDERS_P,
    FETCH_CUSTOMER_ORDERS_F,
    FETCH_CUSTOMER_ORDERS_R,
    FETCH_CUSTOMER_REVIEWS_P,
    FETCH_CUSTOMER_REVIEWS_F,
    FETCH_CUSTOMER_REVIEWS_R,
    FETCH_CUSTOMER_WISHLISTS_BRAND_P,
    FETCH_CUSTOMER_WISHLISTS_BRAND_F,
    FETCH_CUSTOMER_WISHLISTS_BRAND_R,
    FETCH_CUSTOMER_WISHLISTS_PRODUCT_P,
    FETCH_CUSTOMER_WISHLISTS_PRODUCT_F,
    FETCH_CUSTOMER_WISHLISTS_PRODUCT_R,
    FETCH_CUSTOMER_CART_P,
    FETCH_CUSTOMER_CART_F,
    FETCH_CUSTOMER_CART_R,
    IMPORT_CUSTOMERS_P,
    IMPORT_CUSTOMERS_F,
    IMPORT_CUSTOMERS_R,
    CUSTOMER_STATISTICS_P,
    CUSTOMER_STATISTICS_F,
    CUSTOMER_STATISTICS_R,
    CUSTOMER_US_PHONE_P,
    CUSTOMER_US_PHONE_F,
    CUSTOMER_US_PHONE_R
}