import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { Button } from 'reactstrap';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import Papa from 'papaparse';
import 'jspdf-autotable';
import './style.scss';

function ExportOrders({ exportData, loading }) {
    const [activeButton, setActiveButton] = useState(null);

    const columns = [
        { label: "Platform", key: "storeName" },
        { label: "Customer Name", key: "customerName" },
        { label: "Phone Number", key: "phoneNumber" },
        { label: "Total", key: "total" },
        { label: "Tax", key: "tax" },
        { label: "Price", key: "price" },
        { label: "Driver", key: "driverName" },
        { label: "Manager", key: "managerName" },
        { label: "Payment Method", key: "paymentMethod" }
    ];

    const flattenData = (data) => {
        return data.map(({ 
            store, 
            customer, 
            telephone, 
            paymentMethod, 
            orderStaff,
            total,
            tax = 0,
            shippingPrice = 0,
            discountPercent = 0,
        }) => {
            // Ensure all values are valid numbers
            const validTotal = Number(total) || 0;
            const validTax = Number(tax) || 0;
            const validShippingPrice = Number(shippingPrice) || 0;
            const validDiscountPercent = parseFloat(discountPercent) || 0;
    
            // Parse and calculate the discount factor once
            const discountFactor = validDiscountPercent ? 1 - validDiscountPercent / 100 : 1;
    
            // Calculate prices
            const discountedTotal = Number((validTotal + validTax + validShippingPrice) * discountFactor).toFixed(1);
            const discountedTax = Number(validTax * discountFactor).toFixed(1);
            const discountedPrice = Number(validTotal * discountFactor).toFixed(1);
    
            // Helper function to extract driver and manager names
            const findDriverAndManager = (staff) => {
                if (!Array.isArray(staff)) return { driverName: '', managerName: '' };
    
                const driver = staff.find(({ status }) => status === 'delivering')?.user?.username || '';
                const manager = staff.find(({ status }) => status !== 'delivering')?.user?.username || '';
                return { driverName: driver, managerName: manager };
            };
    
            // Extract driver and manager names
            const { driverName, managerName } = findDriverAndManager(orderStaff);
    
            return {
                storeName: store?.name || '',
                customerName: customer ? `${customer.firstname || ''} ${customer.lastname || ''}`.trim() : '',
                phoneNumber: telephone || '',
                total: discountedTotal,
                tax: discountedTax,
                price: discountedPrice,
                driverName,
                managerName,
                paymentMethod: paymentMethod || '',
            };
        });
    };

    const calculateTotalSum = (gridData) =>
        gridData.reduce((sum, row) => sum + (row.totalPrice || 0), 0);

    const createSheetData = (gridData) => {
        const headers = columns.map((col) => col.label);
        const rows = gridData.map((row) =>
            columns.map((col) => row[col.key] || "")
        );
        const totalSum = calculateTotalSum(gridData);

        // Add total sum row
        rows.push([...Array(headers.length).fill(""), "Total Sum", totalSum]);
        return [headers, ...rows];
    };

    const exportToExcel = (gridData, fileName) => {
        const wb = XLSX.utils.book_new();
        const sheetData = createSheetData(gridData);
        const ws = XLSX.utils.aoa_to_sheet(sheetData);

        XLSX.utils.book_append_sheet(wb, ws, "Data");
        XLSX.writeFile(wb, `${fileName}.xlsx`);
    };

    const exportToCSV = (gridData, fileName) => {
        const sheetData = createSheetData(gridData);
        const csv = Papa.unparse(sheetData, { header: false });
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

        saveAs(blob, `${fileName}.csv`);
    };

    const exportToPDF = (gridData, fileName) => {
        const doc = new jsPDF();
        const headers = columns.map((col) => col.label);
        const rows = gridData.map((row) =>
            columns.map((col) => row[col.key] || "")
        );
        const totalSum = calculateTotalSum(gridData);

        // Add total sum row
        rows.push([...Array(headers.length).fill(""), "Total Sum", totalSum]);

        doc.autoTable({ head: [headers], body: rows });
        doc.save(`${fileName}.pdf`);
    };

    const ExportButton = ({ label, icon, onClick, isActive }) => (
        <div className="mw ml-2">
            <Button
                className={`export_button ${isActive && loading ? "loading" : ""}`}
                disabled={loading}
                block
                onClick={onClick}
            >
                <svg aria-hidden="true" focusable="false" className="submit-loader loading" viewBox="0 0 38 38">
                    <g transform="translate(2 2)" stroke="currentColor" stroke-width="4" fill-rule="evenodd">
                    <circle class="st0" stroke-opacity=".5" cx="17" cy="17" r="16.5"></circle>
                    <path class="st1" d="M33.5 17C33.5 7.9 26.1.5 17 .5" transform="rotate(249.767 17 17)">
                        <animateTransform accumulate="none" additive="replace" attributeName="transform" calcMode="linear" dur="1s" from="0 17 17" repeatCount="indefinite" restart="always" to="360 17 17" type="rotate"></animateTransform>
                    </path>
                    </g>
                </svg>
                <i className={`fa ${icon} fa-lg mr-1`}></i>
                {label}
            </Button>
        </div>
    );

    const handleExport = async (type) => {
        setActiveButton(type);  // Set the active button
        try {
            const response = await exportData(); // Call exportData function to return the Promise
    
            if (response.data && Array.isArray(response.data)) {
                const gridData = flattenData(response.data);
    
                switch (type) {
                    case "excel":
                        exportToExcel(gridData, "PurchaseData");
                        break;
                    case "csv":
                        exportToCSV(gridData, "PurchaseData");
                        break;
                    case "pdf":
                        exportToPDF(gridData, "PurchaseData");
                        break;
                    default:
                        console.error("Invalid export type");
                }
            } else {
                console.error("Failed to fetch export data:", response.statusText);
            }
        } catch (error) {
            console.error("An error occurred during export:", error);
        }
    };

    return (
        <div className="export-buttons">
            <ExportButton
                label="Excel"
                icon="fa-file-excel-o"
                onClick={() => handleExport("excel")}
                isActive={activeButton === "excel"}
            />
            <ExportButton
                label="CSV"
                icon="fa-file-csv"
                onClick={() => handleExport("csv")}
                isActive={activeButton === "csv"}
            />
            <ExportButton
                label="PDF"
                icon="fa-file-pdf-o"
                onClick={() => handleExport("pdf")}
                isActive={activeButton === "pdf"}
            />
        </div>
    );
}

export default ExportOrders;