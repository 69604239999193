import  {BRAND_TYPES} from '../types'
import  {brandService} from '../services/brandService'
import {Notification} from 'element-react'
import { PREVIEWURL } from '../../../config/app.json'


/**
 * @name selectElem
 * @description select brand item that edit item
 * @param elem
 * @returns {function(*)}
 */
const selectItem = (elem) => {
  return dispatch => {
    dispatch({
      type : BRAND_TYPES.SELECT_BRAND_ITEMS,
      payload: elem
    })
  }
};


const getItem = (id, token) => {
  return dispatch => {
    return brandService.getItem(id, token).then(data => {
      return dispatch({
        type: BRAND_TYPES.GET_BRAND_ITEM,
        payload: data
      })
    })
  }
};

/**
 * @name getPagAll
 * @returns {function(*)}
 */
const getPagAll= (jwt, page, count, search) => {
  return dispatch => {
    return brandService.getPagAll(jwt, page, count, search).then(options => {
      return dispatch({
        type: BRAND_TYPES.GET_PAG_ALL_BRANDS,
        payload: options
      })
    })
  }
};

/**
 * @name getPaginationRemoved
 * @returns {function(*)}
 */
const getPaginationNotRemoved = (jwt, page, count, search) => {
  return dispatch => {
    return brandService.getPaginationNotRemoved(jwt, page, count, search).then(options => {
      return dispatch({
        type: BRAND_TYPES.GET_PAG_NOT_REMOVED_BRANDS,
        payload : options
      })
    })
  }
};

/**
 * @name getPaginationNotRemoved
 * @returns {function(*)}
 */
const getPaginationRemoved = (jwt, page, count, search) => {
  return dispatch => {
    return brandService.getPaginationRemoved(jwt, page, count, search).then(options => {
      return dispatch({
        type: BRAND_TYPES.GET_PAG_REMOVED_BRANDS,
        payload : options
      })
    })
  }
};

/**
 * @name deleteItem
 * @param elem
 * @returns {function(*)}
 */
const arcive = (ids, jwt) => {
  return dispatch => {
    brandService.bulkDelete(ids,  jwt).then((data) => {
      if (data.success) {
      return dispatch({
        type: BRAND_TYPES.DELETE_BRAND_ITEMS,
        payload : ids
      })
    }
    })
  }
};

/**
 * @name recover
 * @param elem
 * @returns {function(*)}
 */
const recover = (ids, jwt) => {
  return dispatch => {
    brandService.bulkRecover(ids,  jwt).then((data) => {
      if (data.success) {
      return dispatch({
        type: BRAND_TYPES.RECOVER_BRAND_ITEMS,
        payload : ids
      })
    }
    })
  }
};

/**
 * @name bulkDelete
 * @param elem
 * @returns {function(*)}
 */
const bulkDelete = (ids, jwt) => {
  return dispatch => {
    brandService.bulkDelete(ids,  jwt).then((data) => {
      return dispatch({
        type: BRAND_TYPES.ARCHIVE_BRAND_ITEMS,
        payload : data
      })
    })
  }
};

/**
 * @name bulkRecover
 * @param elem
 * @returns {function(*)}
 */
const bulkRecover = (ids, jwt) => {
  return dispatch => {
    brandService.bulkRecover(ids,  jwt).then((data) => {
      return dispatch({
        type: BRAND_TYPES.BULK_RECOVER_BRAND_ITEMS,
        payload : data
      })
    })
  }
};

/**
 * @name bulkDestroy
 * @param elem
 * @returns {function(*)}
 */
const bulkDestroy = (ids, jwt) => {
  return dispatch => {
    brandService.bulkDestroy(ids,  jwt).then((_data) => {
      return dispatch({
        type: BRAND_TYPES.BULK_DESTROY_BRAND_ITEMS,
        payload : ids
      })
    })
  }
};

const newBrand = (language) => {
  return async dispatch => {
      try {
          dispatch({
              type: BRAND_TYPES.NEW_BRAND_FULFILLED,
              payload: language
          })
      } catch (e) {
          dispatch({
              type: BRAND_TYPES.NEW_BRAND_REJECTED,
              payload: e
          });
      }
  }
}

const saveBrand = (brand, jwt, history, discard) => {
  return async dispatch => {
    dispatch({
      type: BRAND_TYPES.SAVE_BRAND_P
    })
    brandService.create(brand, jwt)
        .then(data => {

          Notification.success({
            title: 'Created',
            message: 'Your brand succesfuly created',
          }, 20)

          if (discard) {
            history.push(`${discard}`)
          } else {
            history.push('/catalog/brand')
          }

          dispatch({
            type: BRAND_TYPES.SAVE_BRAND_FULFILLED,
            payload: data
          })

          dispatch({
            type: BRAND_TYPES.BRAND_TAB_PANEL_CHANGE,
            payload:  '1'
          }) 

        })
        .catch(error => {
            if (error.response.status === 422) {
              error.response.data.forEach(err => {
                Notification.error({
                  title: err.path,
                  message: err.message
                }, 20)
              })
            }
            dispatch({
              type: BRAND_TYPES.SAVE_BRAND_R,
              payload: error
            })
          })
        }
    }

const fetchBrandId = (id, token, language) => {
  return async dispatch => {
      try {
            dispatch({
              type: BRAND_TYPES.FETCH_BRAND_PENDING
            })
          const data = await brandService.getItem(id, token)
          dispatch({
              type: BRAND_TYPES.FETCH_BRAND_FULFILLED,
              payload: {data, language}
          })
      } catch (e) {
          dispatch({
              type: 'FETCH_BRAND_REJECTED'
          });
      }
  }
}

const updateBrand = (brand, id, token, history, discard) => {
  return async dispatch => {
    try {

        dispatch({
          type: BRAND_TYPES.UPDATE_BRAND_P
        })

        let data = await brandService.edit(brand, id, token)

        Notification.warning({
          title: 'Updated',
          message: 'Your brand succesfuly updated '
        }, 20)

        if (discard) {
          history.push(`${discard}`)
        } else {
          history.push('/catalog/brand')
        }
        dispatch({
            type: BRAND_TYPES.UPDATE_BRAND_FULFILLED,
            payload: data
        })

        dispatch({
          type: BRAND_TYPES.BRAND_TAB_PANEL_CHANGE,
          payload:  '1'
        }) 
        
    } catch (error) {
        if (error.response.status === 422) {
          error.response.data.forEach(err => {
            Notification.error({
              title: err.path,
              message: err.message
            }, 20)
          })
        }
        dispatch({
          type: BRAND_TYPES.UPDATE_BRAND_R,
          payload: error
        })
      }
    }
  }

  const changeLangTab = ( id, LangTab ) => {
    return async dispatch => {
          dispatch({
            type: BRAND_TYPES.LANG_TAB,
            payload: { 
                languageId: id, 
                LangTab: LangTab 
            }
        })
      }
  }

/**
   * @name fetchBrandSeeAll
   * @returns {function(*)}
*/
const fetchBrandSeeAll = (jwt, page, count, search) => {
  return dispatch => {
    return brandService.getPaginationNotRemoved(jwt, page, count, search).then(options => {
      return dispatch({
        type: BRAND_TYPES.FETCH_BRAND_SEE_ALL,
        payload : options,
        page: page
      })
    })
  }
};



  const destroyBrand = ( token, brand ) => {

    return async dispatch => {

      try {

          await brandService.destroy(token , brand.brandId)
  
          Notification.success( {
            title: 'brand ',
            message: `${brand.name} brand succesfuly deleted `
          }, 20 )

          dispatch( {
              type: BRAND_TYPES.DESTROY_BRAND,
              payload: brand
          } )
          
      } catch (e) {

          Notification.error( {
            title: e.name,
            message: `${e.parent.column}`
          }, 20) 
         
        }
      }
    }

    const getTypes = ( token ) => {

      return async dispatch => {
  
        try {
  
          const { data } = await brandService.getTypes(token)
    
          dispatch( {
              type: BRAND_TYPES.BRAND_SELECT_FILTER_TYPES,
              payload: data
          } )

        } catch (e) {

          console.log(e)
            // Notification.error( {
            //   title: e.name,
            //   message: `${e.parent.column}`
            // }, 20) 
           
          }
        }
      }

      const preview = ( id) => {
        return async _dispatch => {
  
          try {
    
            window.open(`${PREVIEWURL}brands/${id}`, '_blank')
  
          } catch (e) {
  
            console.log(e)
             
            }
          }

      }


export {
    getItem,  
    selectItem, 
    getPaginationNotRemoved,
    fetchBrandSeeAll, 
    getPagAll,
    getPaginationRemoved, 
    arcive, 
    recover, 
    bulkDelete, 
    bulkRecover, 
    newBrand, 
    saveBrand, 
    fetchBrandId, 
    updateBrand, 
    changeLangTab, 
    destroyBrand, 
    bulkDestroy,
    getTypes,
    preview 
}