import  { CUSTOMER_TYPES } from '../../types';
import { Notification } from "element-react";
import {orderService} from "../../services/orderService";
import config from '../../../../config/app'
import axios from 'axios';
import qs from 'qs';

 class CustomerActions {
  constructor(_props) {
    this.api = config['CUSTOMER']
    this.rest  = 'cu/c'
    this.arcive = '/b/'
  }

    paginationCustomer = (jwt, params, page, count, query) => {
      return async dispatch => {
        try {
          const searchString = qs.stringify(query)
          const response = await axios.get(
            this.api + this.rest + `/${params}` + page + '/' + count + '?' + searchString,
            {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
            })
            dispatch({
              type: CUSTOMER_TYPES.CUSTOMER_PAG_F,
              payload: response.data
            })
            return response
        } catch (e) {
          dispatch({
            type: CUSTOMER_TYPES.CUSTOMER_PAG_R,
            payload: e
          })
          return e
        }
      }
    }

    getUsPhone = (jwt, body) => {
      return async dispatch => {
        try {

          dispatch({
            type: CUSTOMER_TYPES.CUSTOMER_US_PHONE_P
          })
          const response = await axios.post(
            this.api + this.rest + '/exists',
            body,
            {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
            })
            dispatch({
              type: CUSTOMER_TYPES.CUSTOMER_US_PHONE_F,
              payload: response.data
            })
            return response
        } catch (e) {
          console.log(e)
          dispatch({
            type: CUSTOMER_TYPES.CUSTOMER_US_PHONE_R,
            payload: e
          })
          return e
        }
      }
    }

    fetchCustomerSeeAll = (jwt, params, page, count, query) => {
      return async dispatch => {
        try {
          const searchString = qs.stringify(query)
          const { data } = await axios.get(
            this.api + this.rest + `/${params}` + page + '/' + count + '?' + searchString,
            {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
            })
            dispatch({
                type: CUSTOMER_TYPES.FETCH_CUSTOMER_SEE_ALL_F,
                payload: data
            })
          } catch (e) {
            dispatch({
                type: CUSTOMER_TYPES.FETCH_CUSTOMER_SEE_ALL_R,
                payload: e
            })
          }
      }
    }

    update = (jwt, body, Type, history, discard) => {
      return async dispatch => {
        try {
            await axios.put(
            this.api + this.rest + `/${body.customerId}`,
            body,
            {
              headers: 
                {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
            })

            Notification.warning({
              title: 'Updated',
              message: 'Customer succesfuly updated '
            }, 20)

            if (discard) {
              history.push(`${discard}`)
            } else {
              history.push('/user/customer')
            }

            dispatch({
              type: CUSTOMER_TYPES[Type + '_UPDATE_F'],
              payload: body
            })
              
        } catch (error) {

          if (error.response.status === 422) {
            error.response.data.forEach(err => {
              Notification.error({
                title: err.path,
                message: err.msg
              }, 20)
            })
          } 

          dispatch({
              type: CUSTOMER_TYPES[Type + '_UPDATE_R'],
              payload: error
          })

        }
      }
    }

    destroy = (jwt, id, Type) => {
      return async dispatch => {
        try {
            await axios.delete(
            this.api + this.rest + `/${id}`,
            {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
            }
          )
            dispatch({
                type: CUSTOMER_TYPES[Type + '_DELETE_F'],
                payload: id
            })
        } catch (e) {
            dispatch({
                type: CUSTOMER_TYPES[Type + '_DELETE_R'],
                payload: e
            })
          }
        }
    }

    getOne = (jwt, id, Type) => {
      return async dispatch => {
        try {
          const response = await axios.get(
            this.api + this.rest + `/${id}`,
          {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
          }

          )
            dispatch({
                type: CUSTOMER_TYPES[Type + '_GET_ONE_F'],
                payload: response.data
            })
        } catch (e) {
            dispatch({
                type: CUSTOMER_TYPES[Type + '_GET_ONE_R'],
                payload: e
            })
          }
        }
    }

    /**
     * @name bulk
     * @param elem
     * @returns {function(*)}
     */
      bulk = (ids, jwt, params, otherDispatchParams) => {
        return async dispatch => {
          try {
             await axios.post(
              this.api + this.rest + this.arcive + params,
              ids,
              {
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`
                  }
              }
            )
              dispatch({
                  type: CUSTOMER_TYPES.CUSTOMER_BULK_ARCIVE_F,
                  payload: ids
              })
              dispatch(this.paginationCustomer(...otherDispatchParams))
              
          } catch (e) {

              dispatch({
                  type: CUSTOMER_TYPES.CUSTOMER_BULK_ARCIVE_R,
                  payload: e
              })

            }
          }
      }

          /**
     * @name bulk
     * @param elem
     * @returns {function(*)}
     */
    bulkDestroy = (ids, jwt, _params, otherDispatchParams) => {
      return async dispatch => {
        try {
           await axios.post(
            this.api + this.rest + '/b/d',
            ids,
            {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
            }
          )
            dispatch({
                type: CUSTOMER_TYPES.CUSTOMER_BULK_DESTROY_F,
                payload: ids
            })
            dispatch(this.paginationCustomer(...otherDispatchParams))
            
        } catch (e) {

            dispatch({
                type: CUSTOMER_TYPES.CUSTOMER_BULK_DESTROY_R,
                payload: e
            })

          }
        }
    }

      create = (jwt, body, Type, history, discard) => {
        return async dispatch => {
            await axios.post(
                this.api + this.rest,
                body,
                {
                    headers: {
                      "Content-Type": "application/json",
                      'Authorization': `Bearer ${jwt}`
                    }
                }
              )
              .then(response => {

                dispatch({
                  type: CUSTOMER_TYPES[Type + '_CREATE_F'],
                  payload: response
                })

                Notification.success({
                  title: 'Created',
                  message: 'Customer succesfuly created',
                }, 20)

                if (discard) {
                  history.push(`${discard}`)
                } else {
                  history.push('/user/customer')
                }
            

              })
              .catch(error => {

                if (error.response.status === 422) {
                  error.response.data.forEach(err => {
                    Notification.error({
                      title: err.path,
                      message: err.msg
                    }, 20)
                  })
                } 

                dispatch({
                  type: CUSTOMER_TYPES[Type + '_CREATE_R'],
                  payload: error
                })

              })
          }
      }

      getOrder = (jwt, page, count, filter, Type) => {
        return async dispatch => {
          return orderService.getPaginationPg(jwt, page, count, filter).then(orders => {
            return dispatch({
              type: CUSTOMER_TYPES[Type + '_GET_ORDER'],
              payload : orders
            })
          })  
        }
      }


    paginationCustomerOrders = (jwt, customerId, page, count) => {
      return async dispatch => {
        try {

          dispatch({
            type: CUSTOMER_TYPES.FETCH_CUSTOMER_ORDERS_P
          })
          
          const response = await axios.get(
            this.api + this.rest + `/orders/${customerId}/${page}/${count}`,
            {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
            })

          dispatch({
            type: CUSTOMER_TYPES.FETCH_CUSTOMER_ORDERS_F,
            payload: response.data
          })

        } catch (e) {

          dispatch({
            type: CUSTOMER_TYPES.FETCH_CUSTOMER_ORDERS_R,
            payload: e
          })

        }
      }
    }

    paginationCustomerReviews = (jwt, customerId, page, count) => {
      return async dispatch => {
        try {

          dispatch({
            type: CUSTOMER_TYPES.FETCH_CUSTOMER_REVIEWS_P
          })
          
          const response = await axios.get(
            this.api + this.rest + `/reviews/${customerId}/${page}/${count}`,
            {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
            })

          dispatch({
            type: CUSTOMER_TYPES.FETCH_CUSTOMER_REVIEWS_F,
            payload: response.data
          })

        } catch (e) {

          dispatch({
            type: CUSTOMER_TYPES.FETCH_CUSTOMER_REVIEWS_R,
            payload: e
          })

        }
      }
    }

    paginationCustomerWistlistsProduct = (jwt, customerId, page, count) => {
      return async dispatch => {
        try {

          dispatch({
            type: CUSTOMER_TYPES.FETCH_CUSTOMER_WISHLISTS_PRODUCT_P
          })
          
          const response = await axios.get(
            this.api + this.rest + `/wishlists/product/${customerId}/${page}/${count}`,
            {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
            })

          dispatch({
            type: CUSTOMER_TYPES.FETCH_CUSTOMER_WISHLISTS_PRODUCT_F,
            payload: response.data
          })

        } catch (e) {

          dispatch({
            type: CUSTOMER_TYPES.FETCH_CUSTOMER_WISHLISTS_PRODUCT_R,
            payload: e
          })

        }
      }
    }

    paginationCustomerWistlistsBrand = (jwt, customerId, page, count) => {
      return async dispatch => {
        try {

          dispatch({
            type: CUSTOMER_TYPES.FETCH_CUSTOMER_WISHLISTS_BRAND_P
          })
          
          const response = await axios.get(
            this.api + this.rest + `/wishlists/brand/${customerId}/${page}/${count}`,
            {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
            })

          dispatch({
            type: CUSTOMER_TYPES.FETCH_CUSTOMER_WISHLISTS_BRAND_F,
            payload: response.data
          })

        } catch (e) {

          dispatch({
            type: CUSTOMER_TYPES.FETCH_CUSTOMER_WISHLISTS_BRAND_R,
            payload: e
          })

        }
      }
    }


    paginationCustomerCart = (jwt, customerId, page, count, query) => {
      return async dispatch => {
        try {

          const searchString = qs.stringify(query)

          dispatch({
            type: CUSTOMER_TYPES.FETCH_CUSTOMER_CART_P
          })
          
          const response = await axios.get(
            this.api + this.rest + `/cart/${customerId}/${page}/${count}?${searchString}`,
            {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
            })

          dispatch({
            type: CUSTOMER_TYPES.FETCH_CUSTOMER_CART_F,
            payload: response.data
          })

        } catch (e) {

          dispatch({
            type: CUSTOMER_TYPES.FETCH_CUSTOMER_CART_R,
            payload: e
          })

        }
      }
    }

    importCustomers = (jwt, body) => {
      return async dispatch => {
        try {

          dispatch({
            type: CUSTOMER_TYPES.IMPORT_CUSTOMERS_P
          })
          
          const response = await axios.post(
            this.api + this.rest + `/import/${body.customerGroupId}`,
            body,
            {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
            }
          )

          dispatch({
            type: CUSTOMER_TYPES.IMPORT_CUSTOMERS_F,
            payload: response.data
          })

          return response

        } catch (e) {

          dispatch({
            type: CUSTOMER_TYPES.IMPORT_CUSTOMERS_R,
            payload: e
          })

          return e

        }
      }
    }

    getStatistics = (jwt, query) => {
      return async dispatch => {
          try {
  
              const searchString = qs.stringify(query)
  
              dispatch({
                  type: CUSTOMER_TYPES.CUSTOMER_STATISTICS_P
              })
              const response = await axios.get(`${this.api + `${this.rest}/s/statistics?${searchString}`}`,
                  {
                      headers: {
                          'Content-Type': 'application/json',
                          'Authorization': `Bearer ${jwt}`
                      }
                  }
              )
  
              dispatch({
                  type: CUSTOMER_TYPES.CUSTOMER_STATISTICS_F,
                  payload: response.data
              })
  
          } catch (e) {

              dispatch({
                  type: CUSTOMER_TYPES.CUSTOMER_STATISTICS_R,
                  payload: e
              })

          }
      }
  };
    
}




export default new CustomerActions()