import  { WAREHOUSE_TYPES } from '../../types'
import config from '../../../../config/app'
import axios from 'axios'
import qs from 'qs'
import {Notification} from 'element-react'

 class BusinessActions {
  constructor(_props) {
    this.api = config['INVENTORY_API']
    this.rest  = 'w/'
    this.arcive = 'b/'
  }

    pagination = (jwt, params, page, count, query) => {
      return async dispatch => {
        try {
          const searchString = qs.stringify(query)
          const response = await axios.get(
          this.api + this.rest + `${params}` + page + '/' + count + '?' + searchString,
          {
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${jwt}`
            }
          })

          dispatch({
            type: WAREHOUSE_TYPES.WAREHOUSE_PAG_F,
            payload: response.data
          })

        } catch (e) {

          dispatch({
            type: WAREHOUSE_TYPES.WAREHOUSE_PAG_R,
            payload: e
          })

        }
      }
    }

    paginationProducts = (jwt, id, page, count, query) => {
      return async dispatch => {
        try {
          dispatch({
            type: WAREHOUSE_TYPES.WAREHOUSE_PRODUCTS_PAG_P
          })

          const searchString = qs.stringify(query)
          const response = await axios.get(
          this.api + this.rest + `/p/${id}/${page}/${count}` + '?' + searchString,
          {
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${jwt}`
            }
          })

          dispatch({
            type: WAREHOUSE_TYPES.WAREHOUSE_PRODUCTS_PAG_F,
            payload: response.data
          })

        } catch (e) {

          dispatch({
            type: WAREHOUSE_TYPES.WAREHOUSE_PRODUCTS_PAG_R,
            payload: e
          })

        }
      }
    }

    seeAll = (jwt, page, count, query, search) => {
      return async dispatch => {
        try {
          dispatch({
            type: WAREHOUSE_TYPES.FETCH_WAREHOUSE_SEE_ALL_P
          })
          const searchString = qs.stringify(query)
          const response = await axios.get(
          this.api + this.rest + `/r/` + page + '/' + count + '?' + searchString,
          {
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${jwt}`
            }
          })

          dispatch({
            type: WAREHOUSE_TYPES.FETCH_WAREHOUSE_SEE_ALL_F,
            payload: response.data,
            search: search
          })

        } catch (e) {

          dispatch({
            type: WAREHOUSE_TYPES.FETCH_WAREHOUSE_SEE_ALL_R,
            payload: e
          })

        }
      }
    }

    update = (jwt, body, Type, history, discard) => {
      return async dispatch => {
        try {

          dispatch({
            type: WAREHOUSE_TYPES[Type + '_UPDATE_P']
          })

          await axios.put(
          this.api + this.rest + `/${body.warehouseId}`,
          body,
          {
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${jwt}`
            }
          })

          Notification.warning({
            title: 'Updated',
            message: 'Warehouse succesfuly updated '
          }, 20)
  
          if (discard) {
            history.push(`${discard}`)
          } else {
            history.push('/inventory/warehouse')
          }

          dispatch({
            type: WAREHOUSE_TYPES[Type + '_UPDATE_F'],
            payload: body
          })
            
        } catch (e) {

          dispatch({
              type: WAREHOUSE_TYPES[Type + '_UPDATE_R'],
              payload: e
          })

          if (e.response.status === 422 && Array.isArray(e.response.data)) {
            e.response.data.forEach(err => {
                 Notification.error({
                      title: err.path,
                      message: err.msg || err.message
                 }, 20)
            })
          } 

        }
      }
    }

    destroy = (jwt, id, Type) => {
      return async dispatch => {
        try {
          await axios.delete(
            this.api + this.rest + `/${id}`,
          {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
          }

          )
            dispatch({
                type: WAREHOUSE_TYPES[Type + '_DELETE_F'],
                payload: id
            })
        } catch (e) {
            dispatch({
                type: WAREHOUSE_TYPES[Type + '_DELETE_R'],
                payload: e
            })
          }
        }
    }

    getOne = (jwt, id, Type) => {
      return async dispatch => {
        try {
          const response = await axios.get(
            this.api + this.rest + `/${id}`,
            {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
            }
          )
            dispatch({
                type: WAREHOUSE_TYPES[Type + '_GET_ONE_F'],
                payload: response.data
            })
        } catch (e) {
            dispatch({
                type: WAREHOUSE_TYPES[Type + '_GET_ONE_R'],
                payload: e
            })
          }
        }
    }

    /**
     * @name bulk
     * @param elem
     * @returns {function(*)}
     */
      bulk = (ids, jwt, params, otherDispatchParams) => {
        return async dispatch => {
          try {
             await axios.post(
              this.api + this.rest + this.arcive + params,
              ids,
              {
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`
                  }
              }
            )
              dispatch({
                  type: WAREHOUSE_TYPES.WAREHOUSE_BULK_ARCIVE_F,
                  payload: ids
              })

              dispatch(this.pagination(...otherDispatchParams))
              
          } catch (e) {

              dispatch({
                  type: WAREHOUSE_TYPES.WAREHOUSE_BULK_ARCIVE_R,
                  payload: e
              })

            }
          }
      }
      /**
        * @name bulk
        * @param elem
        * @returns {function(*)}
      */
       bulkDestroy = (ids, jwt, _params, otherDispatchParams) => {
         return async dispatch => {
           try {
              await axios.post(
               this.api + this.rest + 'b/d',
               ids,
               {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
              }
             )
               dispatch({
                   type: WAREHOUSE_TYPES.WAREHOUSE_BULK_DESTROY_F,
                   payload: ids
               })
 
               dispatch(this.pagination(...otherDispatchParams))
               
           } catch (e) {
 
               dispatch({
                   type: WAREHOUSE_TYPES.WAREHOUSE_BULK_DESTROY_R,
                   payload: e
               })
 
             }
           }
       }

        create = (jwt, body, Type, history, discard) => {
        return async dispatch => {
            dispatch({
              type: WAREHOUSE_TYPES[Type + '_CREATE_P']
            })
            
            await axios.post(
                this.api + this.rest,
                body,
                {
                    headers: {
                      "Content-Type": "application/json",
                      'Authorization': `Bearer ${jwt}`
                    }
                }
              )
              .then(response => {

                Notification.success({
                  title: 'Created',
                  message: 'Warehouse succesfuly created',
                }, 20)
        
                if (discard) {
                  history.push(`${discard}`)
                } else {
                  history.push('/inventory/warehouse')
                }
      
                dispatch({
                    type: WAREHOUSE_TYPES[Type + '_CREATE_F'],
                    payload: response
                })

              })
              .catch(e => {

                  dispatch({
                      type: WAREHOUSE_TYPES[Type + '_CREATE_R'],
                      payload: e
                  })

                  if (e.response.status === 422 && Array.isArray(e.response.data)) {
                    e.response.data.forEach(err => {
                         Notification.error({
                              title: err.path,
                              message: err.msg || err.message
                         }, 20)
                    })
                  } 

              })
            }
        }

    getStatistics = (jwt, query) => {
      return async dispatch => {
        try {

          const searchString = qs.stringify(query)

          dispatch({
            type: WAREHOUSE_TYPES.GET_WAREHOUSE_STATISTICS_P,
          })

          const response = await axios.get(`${this.api + `${this.rest}a/statistics?${searchString}`}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${jwt}`
            }
          })

          dispatch({
            type: WAREHOUSE_TYPES.GET_WAREHOUSE_STATISTICS_F,
            payload: response.data
          })

        } catch (e) {
          dispatch({
            type: WAREHOUSE_TYPES.GET_WAREHOUSE_STATISTICS_R,
            payload: e
          })
        }
      }
    };
}


export default new BusinessActions()