const BUSINESS_PAG_F = 'BUSINESS_PAG_L_F';
const BUSINESS_PAG_R = 'BUSINESS_PAG_L_R';
const BUSINESS_SIZE_CHANGE = 'BUSINESS_SIZE_CHANGE'
const BUSINESS_PAGE_CHANGE = 'BUSINESS_PAGE_CHANGE'
const BUSINESS_FILETR_TABE_CHANGE = 'BUSINESS_FILETR_TABE_CHANGE'
const BUSINESS_UPDATE_P = 'BUSINESS_UPDATE_P'
const BUSINESS_UPDATE_F = 'BUSINESS_UPDATE_F'
const BUSINESS_UPDATE_R = 'BUSINESS_UPDATE_R'
const BUSINESS_CHECKBOX_CHANGE = 'BUSINESS_CHECKBOX_CHANGE'
const BUSINESS_BULK_ARCIVE_F = 'BUSINESS_BULK_ARCIVE_F'
const BUSINESS_BULK_ARCIVE_R = 'BUSINESS_BULK_ARCIVE_R'
const BUSINESS_BULK_DESTROY_F = 'BUSINESS_BULK_DESTROY_F'
const BUSINESS_BULK_DESTROY_R = 'BUSINESS_BULK_DESTROY_R'
const BUSINESS_TAB_PANEL_CHANGE = 'BUSINESS_TAB_PANEL_CHANGE'
const BUSINESS_CREATE_P = 'BUSINESS_CREATE_P'
const BUSINESS_CREATE_F = 'BUSINESS_CREATE_F'
const BUSINESS_CREATE_R = 'BUSINESS_CREATE_R'
const BUSINESS_GET_ONE_F = 'BUSINESS_GET_ONE_F'
const BUSINESS_GET_ONE_R = 'BUSINESS_GET_ONE_R'
const BUSINESS_REQUIRED_VALIDATION_F = 'BUSINESS_REQUIRED_VALIDATION_F'
const BUSINESS_REQUIRED_VALIDATION_R = 'BUSINESS_REQUIRED_VALIDATION_R'
const NEW_BUSINESS = 'NEW_BUSINESS'
const BUSINESS_DATA_FILTER = 'BUSINESS_DATA_FILTER'
const BUSINESS_DELETE_F = 'BUSINESS_DELETE_F'
const BUSINESS_DELETE_R = 'BUSINESS_DELETE_R'
const BUSINESS_LIVE_DATA = 'BUSINESS_LIVE_DATA'
const BUSINESS_DATA_FILTER_SEARCH = 'BUSINESS_DATA_FILTER_SEARCH'
const BUSINESS_FILTER_IS_OPEN = 'BUSINESS_FILTER_IS_OPEN'
const BUSINESS_LANGUAGE_PANEL_CHANGE = 'BUSINESS_LANGUAGE_PANEL_CHANGE'
const BUSINESS_DATA_FILTER_SEARCH_RESET = 'BUSINESS_DATA_FILTER_SEARCH_RESET'
const BUSINESS_DATA_FILTER_ALL_SEARCH_RESET = 'BUSINESS_DATA_FILTER_ALL_SEARCH_RESET'
const FETCH_BUSINESS_SEE_ALL_P = 'FETCH_BUSINESS_SEE_ALL_P'
const FETCH_BUSINESS_SEE_ALL_F = 'FETCH_BUSINESS_SEE_ALL_F'
const FETCH_BUSINESS_SEE_ALL_R = 'FETCH_BUSINESS_SEE_ALL_R'
const GET_BUSINESS_STATISTICS_P = 'GET_BUSINESS_STATISTICS_P'
const GET_BUSINESS_STATISTICS_F = 'GET_BUSINESS_STATISTICS_F'
const GET_BUSINESS_STATISTICS_R = 'GET_BUSINESS_STATISTICS_R'

export {
    BUSINESS_PAG_F,
    BUSINESS_PAG_R,
    BUSINESS_SIZE_CHANGE,
    BUSINESS_PAGE_CHANGE,
    BUSINESS_FILETR_TABE_CHANGE,
    BUSINESS_UPDATE_P,
    BUSINESS_UPDATE_F,
    BUSINESS_UPDATE_R,
    BUSINESS_CHECKBOX_CHANGE,
    BUSINESS_BULK_ARCIVE_F,
    BUSINESS_BULK_ARCIVE_R,
    BUSINESS_BULK_DESTROY_F,
    BUSINESS_BULK_DESTROY_R,
    BUSINESS_TAB_PANEL_CHANGE,
    BUSINESS_CREATE_P,
    BUSINESS_CREATE_F,
    BUSINESS_CREATE_R,
    BUSINESS_GET_ONE_F,
    BUSINESS_GET_ONE_R,
    BUSINESS_REQUIRED_VALIDATION_F,
    BUSINESS_REQUIRED_VALIDATION_R,
    NEW_BUSINESS,
    BUSINESS_DATA_FILTER,
    BUSINESS_DELETE_F,
    BUSINESS_DELETE_R,
    BUSINESS_LIVE_DATA,
    BUSINESS_DATA_FILTER_SEARCH,
    BUSINESS_FILTER_IS_OPEN,
    BUSINESS_LANGUAGE_PANEL_CHANGE,
    BUSINESS_DATA_FILTER_SEARCH_RESET,
    BUSINESS_DATA_FILTER_ALL_SEARCH_RESET,
    FETCH_BUSINESS_SEE_ALL_P,
    FETCH_BUSINESS_SEE_ALL_F,
    FETCH_BUSINESS_SEE_ALL_R,
    GET_BUSINESS_STATISTICS_P,
    GET_BUSINESS_STATISTICS_F,
    GET_BUSINESS_STATISTICS_R
}