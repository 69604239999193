import React from 'react';
import Loadable from 'react-loadable'

import DefaultLayout from './containers/DefaultLayout';
import Orders from './projectNulla/sales/Orders/Orders';
import Dashboard from './views/Dashboard';

function Loading() {
  return <div className="loader"> </div>;
}

const Breadcrumbs = Loadable({
  loader: () => import('./views/Base/Breadcrumbs'),
  loading: Loading,
});

const Cards = Loadable({
  loader: () => import('./views/Base/Cards'),
  loading: Loading,
});

const Carousels = Loadable({
  loader: () => import('./views/Base/Carousels'),
  loading: Loading,
});

const Collapses = Loadable({
  loader: () => import('./views/Base/Collapses'),
  loading: Loading,
});

const Dropdowns = Loadable({
  loader: () => import('./views/Base/Dropdowns'),
  loading: Loading,
});

const Forms = Loadable({
  loader: () => import('./views/Base/Forms'),
  loading: Loading,
});

const Jumbotrons = Loadable({
  loader: () => import('./views/Base/Jumbotrons'),
  loading: Loading,
});

const ListGroups = Loadable({
  loader: () => import('./views/Base/ListGroups'),
  loading: Loading,
});

const Navbars = Loadable({
  loader: () => import('./views/Base/Navbars'),
  loading: Loading,
});

const Navs = Loadable({
  loader: () => import('./views/Base/Navs'),
  loading: Loading,
});

const Paginations = Loadable({
  loader: () => import('./views/Base/Paginations'),
  loading: Loading,
});

const Popovers = Loadable({
  loader: () => import('./views/Base/Popovers'),
  loading: Loading,
});

const ProgressBar = Loadable({
  loader: () => import('./views/Base/ProgressBar'),
  loading: Loading,
});

const Switches = Loadable({
  loader: () => import('./views/Base/Switches'),
  loading: Loading,
});

const Tables = Loadable({
  loader: () => import('./views/Base/Tables'),
  loading: Loading,
});

const Tabs = Loadable({
  loader: () => import('./views/Base/Tabs'),
  loading: Loading,
});

const Tooltips = Loadable({
  loader: () => import('./views/Base/Tooltips'),
  loading: Loading,
});

const BrandButtons = Loadable({
  loader: () => import('./views/Buttons/BrandButtons'),
  loading: Loading,
});

const ButtonDropdowns = Loadable({
  loader: () => import('./views/Buttons/ButtonDropdowns'),
  loading: Loading,
});

const ButtonGroups = Loadable({
  loader: () => import('./views/Buttons/ButtonGroups'),
  loading: Loading,
});

const Buttons = Loadable({
  loader: () => import('./views/Buttons/Buttons'),
  loading: Loading,
});

const Charts = Loadable({
  loader: () => import('./views/Charts'),
  loading: Loading,
});

// const Dashboard = Loadable({
//   loader: () => import('./views/Dashboard'),
//   loading: Loading,
// });

const CoreUIIcons = Loadable({
  loader: () => import('./views/Icons/CoreUIIcons'),
  loading: Loading,
});

const Flags = Loadable({
  loader: () => import('./views/Icons/Flags'),
  loading: Loading,
});

const FontAwesome = Loadable({
  loader: () => import('./views/Icons/FontAwesome'),
  loading: Loading,
});

const SimpleLineIcons = Loadable({
  loader: () => import('./views/Icons/SimpleLineIcons'),
  loading: Loading,
});

const Alerts = Loadable({
  loader: () => import('./views/Notifications/Alerts'),
  loading: Loading,
});

const Badges = Loadable({
  loader: () => import('./views/Notifications/Badges'),
  loading: Loading,
});

const Modals = Loadable({
  loader: () => import('./views/Notifications/Modals'),
  loading: Loading,
});

const Colors = Loadable({
  loader: () => import('./views/Theme/Colors'),
  loading: Loading,
});

const Typography = Loadable({
  loader: () => import('./views/Theme/Typography'),
  loading: Loading,
});

const Widgets = Loadable({
  loader: () => import('./views/Widgets/Widgets'),
  loading: Loading,
});

const DeliveryRoutesItem = Loadable({
  loader: () => import('./projectNulla/delivery/routes/Item'),
  loading: Loading,
});

const DeliveryRoutesList = Loadable({
  loader: () => import('./projectNulla/delivery/routes/List'),
  loading: Loading,
});

const Delivery = Loadable({
  loader: () => import('./projectNulla/delivery/delivery/Item'),
  loading: Loading,
});

const User = Loadable({
  loader: () => import('./views/Users/User'),
  loading: Loading,
});

const Messages = Loadable({
  loader: () => import('./projectNulla/catalogs/Messages'),
  loading: Loading,
});

const Information = Loadable({
  loader: () => import('./projectNulla/catalogs/Information/Information'),
  loading: Loading,
});

const InformationCreate = Loadable({
  loader: () => import('./projectNulla/catalogs/Information/InformationCreate'),
  loading: Loading,
});

const Reviews = Loadable({
  loader: () => import('./projectNulla/catalogs/Review/Reviews'),
  loading: Loading,
});

const ReviewCreate = Loadable({
  loader: () => import('./projectNulla/catalogs/Review/ReviewCreate'),
  loading: Loading,
});

const Manufacturers = Loadable({
  loader: () => import('./projectNulla/catalogs/Manufacturers/ManufacturerList'),
  loading: Loading,
});

const ManufacturerCreate = Loadable({
  loader: () => import('./projectNulla/catalogs/Manufacturers/ManufacturerCreate'),
  loading: Loading,
});

const Options = Loadable({
  loader: () => import('./projectNulla/catalogs/Options/Options'),
  loading: Loading,
});

const OptionCreate = Loadable({
  loader: () => import('./projectNulla/catalogs/Options/OptionCreate'),
  loading: Loading,
});

const AttributeGroups = Loadable({
  loader: () => import('./projectNulla/catalogs/attributes/AttributeGroups/AttributeGroups'),
  loading: Loading,
});

const AttributeGroupsCreate = Loadable({
  loader: () => import('./projectNulla/catalogs/attributes/AttributeGroups/AttributeGroupsCreate'),
  loading: Loading,
});

const Attribute = Loadable({
  loader: () => import('./projectNulla/catalogs/attributes/Attribute'),
  loading: Loading,
});

const AttributeCreate = Loadable({
  loader: () => import('./projectNulla/catalogs/attributes/AttributeCreate'),
  loading: Loading,
});

const Filters = Loadable({
  loader: () => import('./projectNulla/catalogs/Filters/Filters'),
  loading: Loading,
});

const FilterCreate = Loadable({
  loader: () => import('./projectNulla/catalogs/Filters/FilterCreate'),
  loading: Loading,
});

const Products = Loadable({
  loader: () => import('./projectNulla/catalogs/Products/Products'),
  loading: Loading,
});

const ProductCreate = Loadable({
  loader: () => import('./projectNulla/catalogs/Products/ProductCreate'),
  loading: Loading,
});

const ProductsImport = Loadable({
  loader: () => import('./projectNulla/catalogs/Products/ProductImport'),
  loading: Loading,
});

const ProductsExport = Loadable({
  loader: () => import('./projectNulla/catalogs/Products/ProductExport/ProductExport'),
  loading: Loading,
});

const ProductGroups = Loadable({
  loader: () => import('./projectNulla/catalogs/Products/ProductGroup/ProductGroups'),
  loading: Loading,
});

const ProductGroupCreate = Loadable({
  loader: () => import('./projectNulla/catalogs/Products/ProductGroup/ProductGroupCreate'),
  loading: Loading,
});

const BrandList = Loadable({
  loader: () => import('./projectNulla/catalogs/Brands/BrandList'),
  loading: Loading,
});

const BrandCreate = Loadable({
  loader: () => import('./projectNulla/catalogs/Brands/BrandCreate'),
  loading: Loading,
});

const Categories = Loadable({
  loader: () => import('./projectNulla/catalogs/Category/Categories'),
  loading: Loading,
});

const CategoryCreate = Loadable({
  loader: () => import('./projectNulla/catalogs/Category/CategoryCreate'),
  loading: Loading,
});

const RecurringProfiles = Loadable({
  loader: () => import('./projectNulla/catalogs/RecurringProfiles'),
  loading: Loading,
});

/*---Media---*/

const Image = Loadable({
  loader: () => import('./projectNulla/media/Image/Images'),
  loading: Loading,
});

const Video = Loadable({
  loader: () => import('./projectNulla/media/Video/Video'),
  loading: Loading,
});

const VideoCreate = Loadable({
  loader: () => import('./projectNulla/media/Video/VideoCreate'),
  loading: Loading,
});

const Icon = Loadable({
  loader: () => import('./projectNulla/media/Icon/Icon'),
  loading: Loading,
});

const IconCreate = Loadable({
  loader: () => import('./projectNulla/media/Icon/IconCreate'),
  loading: Loading,
});

const ImageCreate = Loadable({
  loader: () => import('./projectNulla/media/Image/ImagesCreate'),
  loading: Loading,
});

const Downloads = Loadable({
  loader: () => import('./projectNulla/media/Download/Downloads'),
  loading: Loading,
});

const Slide = Loadable({
  loader: () => import('./projectNulla/media/Slide/Slides'),
  loading: Loading,
});

// /*---sales---*/
// const Orders = Loadable({
//   loader: () => import('./projectNulla/sales/Orders/Orders'),
//   loading: Loading,
// });

const OrderItem = Loadable({
  loader: () => import('./projectNulla/sales/Orders/OrderItem/OrderItem'),
  loading: Loading,
});

const SaleRecurringProfiles = Loadable({
  loader: () =>  import('./projectNulla/sales/SaleRecurringProfiles') ,
  loading: Loading,
});

const Returns = Loadable({
  loader: () =>  import('./projectNulla/sales/Returns') ,
  loading: Loading,
});

const Users = Loadable({
  loader: () => import('./projectNulla/system/Users/Users'),
  loading: Loading,
});

// Customer Service
const Customers = Loadable({
  loader: () => import('./projectNulla/customer/Customer/Customers'),
  loading: Loading,
});

const CustomerCreate = Loadable({
  loader: () => import('./projectNulla/customer/Customer/CustomerCreate'),
  loading: Loading,
});

// Customer Group Service
const CustomerGroup = Loadable({
  loader: () => import('./projectNulla/customer/CustomerGroup/CustomerGroups'),
  loading: Loading,
});

const CustomerGroupCreate = Loadable({
  loader: () => import('./projectNulla/customer/CustomerGroup/CustomerGroupsCreate'),
  loading: Loading,
});

const CustomerImport = Loadable({
  loader: () => import('./projectNulla/customer/CustomerGroup/CustomerGroupImport'),
  loading: Loading,
});

// Chat Service
const Chat = Loadable({
  loader: () => import('./projectNulla/chat/Chat'),
  loading: Loading,
});

const UsersCreate = Loadable({
  loader: () => import('./projectNulla/system/Users/UsersCreate'),
  loading: Loading,
});

const UserGroups = Loadable({
  loader: () => import('./projectNulla/system/UserGroups/UserGroups'),
  loading: Loading,
});

const UserGroupCreate = Loadable({
  loader: () => import('./projectNulla/system/UserGroups/UserGroupCreate'),
  loading: Loading,
});

const TimeTracking = Loadable({
  loader: () => import('./projectNulla/system/UserTimeTracking/List'),
  loading: Loading,
});

const TimeTrackingCreate = Loadable({
  loader: () => import('./projectNulla/system/UserTimeTracking/Item'),
  loading: Loading,
});

const Equipment = Loadable({
  loader: () => import('./projectNulla/business/Equipment/List'),
  loading: Loading,
});

const EquipmentCreate = Loadable({
  loader: () => import('./projectNulla/business/Equipment/Item'),
  loading: Loading,
});

const Languages = Loadable({
  loader: () => import('./projectNulla/system/Languages/Languages'),
  loading: Loading,
});

const LanguagesCreate = Loadable({
  loader: () => import('./projectNulla/system/Languages/LanguageCreate'),
  loading: Loading,
});

const Currency = Loadable({
  loader: () => import('./projectNulla/system/Currency/CurrencyList'),
  loading: Loading,
});

const CurrencyCreate = Loadable({
  loader: () => import('./projectNulla/system/Currency/CurrencyCreate'),
  loading: Loading,
});

const NotificationType = Loadable({
  loader: () => import('./projectNulla/system/NotificationType/List'),
  loading: Loading,
});

const NotificationTypeItem = Loadable({
  loader: () => import('./projectNulla/system/NotificationType/Item'),
  loading: Loading,
});

const Notification = Loadable({
  loader: () => import('./projectNulla/system/Notification/Settings'),
  loading: Loading,
})

const NotificationSound = Loadable({
  loader: () => import('./projectNulla/system/NotificationSound/List'),
  loading: Loading,
})

const NotificationSoundItem = Loadable({
  loader: () => import('./projectNulla/system/NotificationSound/Item'),
  loading: Loading,
});

const Discounts = Loadable({
  loader: () => import('./projectNulla/discount/Discount/List'),
  loading: Loading,
});

const DiscountCreate = Loadable({
  loader: () => import('./projectNulla/discount/Discount/Item'),
  loading: Loading,
});

const PromoCodes = Loadable({
  loader: () => import('./projectNulla/discount/PromoCode/List'),
  loading: Loading,
});

const PromoCodeCreate = Loadable({
  loader: () => import('./projectNulla/discount/PromoCode/Item'),
  loading: Loading,
});

const Stors = Loadable({
  loader: () => import('./projectNulla/store/Store/List'),
  loading: Loading,
});

const DigitalCard = Loadable({
  loader: () => import('./projectNulla/business/DigitalCard/List'),
  loading: Loading,
});

const DigitalCardCreate = Loadable({
  loader: () => import('./projectNulla/business/DigitalCard/Item'),
  loading: Loading,
});

const Promotion = Loadable({
  loader: () => import('./projectNulla/promotion/Promotion/List'),
  loading: Loading,
});

const PromotionItem = Loadable({
  loader: () => import('./projectNulla/promotion/Promotion/Item'),
  loading: Loading,
});

const Business = Loadable({
  loader: () => import('./projectNulla/business/Business/List'),
  loading: Loading,
});

const BusinessItem = Loadable({
  loader: () => import('./projectNulla/business/Business/Item'),
  loading: Loading,
});

const Plan = Loadable({
  loader: () => import('./projectNulla/business/Plan/List'),
  loading: Loading,
});

const Transaction = Loadable({
  loader: () => import('./projectNulla/business/Transaction/List'),
  loading: Loading,
});

const PlanItem = Loadable({
  loader: () => import('./projectNulla/business/Plan/Item'),
  loading: Loading,
});

const StoreCreate = Loadable({
  loader: () => import('./projectNulla/store/Store/Item'),
  loading: Loading,
});

const Calls = Loadable({
  loader: () => import('./projectNulla/call/Calls'),
  loading: Loading,
});

const Sms = Loadable({
  loader: () => import('./projectNulla/sms/SmsList'),
  loading: Loading,
});

const SendSms = Loadable({
  loader: () => import('./projectNulla/sms/SendSms'),
  loading: Loading,
});

const SmsPlan = Loadable({
  loader: () => import('./projectNulla/smsServie/plan/Plan'),
  loading: Loading,
});

const SendBulkSms = Loadable({
  loader: () => import('./projectNulla/smsServie/sms/SendSms'),
  loading: Loading,
});

const SmsList = Loadable({
  loader: () => import('./projectNulla/smsServie/sms/SmsList'),
  loading: Loading,
});

const SmsGroupList = Loadable({
  loader: () => import('./projectNulla/smsServie/smsGroup/SmsGroupList'),
  loading: Loading,
});

const SmsGroupItem = Loadable({
  loader: () => import('./projectNulla/smsServie/smsGroup/SmsGroupItem'),
  loading: Loading,
});

const SmsDomainList = Loadable({
  loader: () => import('./projectNulla/smsServie/domain/DomainList'),
  loading: Loading,
});

const SmsDomainItem = Loadable({
  loader: () => import('./projectNulla/smsServie/domain/DomainItem'),
  loading: Loading,
});

const SmsPhoneList = Loadable({
  loader: () => import('./projectNulla/smsServie/phone/PhoneList'),
  loading: Loading,
});

const SmsPhoneItem = Loadable({
  loader: () => import('./projectNulla/smsServie/phone/PhoneItem'),
  loading: Loading,
});

const CampaignList = Loadable({
  loader: () => import('./projectNulla/smsServie/campaign/CampaignList'),
  loading: Loading,
});

const CampaignItem = Loadable({
  loader: () => import('./projectNulla/smsServie/campaign/CampaignItem'),
  loading: Loading,
});

const Call = Loadable({
  loader: () => import('./projectNulla/callUser/List'),
  loading: Loading,
});

const CallItem = Loadable({
  loader: () => import('./projectNulla/callUser/Item'),
  loading: Loading,
});

const SendBulkCall = Loadable({
  loader: () => import('./projectNulla/callService/call/SendCall'),
  loading: Loading,
});

const CallList = Loadable({
  loader: () => import('./projectNulla/callService/call/CallList'),
  loading: Loading,
});

const CallGroupList = Loadable({
  loader: () => import('./projectNulla/callService/callGroup/CallGroupList'),
  loading: Loading,
});

const CallGroupItem = Loadable({
  loader: () => import('./projectNulla/callService/callGroup/CallGroupItem'),
  loading: Loading,
});

const Franchise = Loadable({
  loader: () => import('./projectNulla/store/Franchise/List'),
  loading: Loading,
});

const FranchiseCreate = Loadable({
  loader: () => import('./projectNulla/store/Franchise/Item'),
  loading: Loading,
});

const Link = Loadable({
  loader: () => import('./projectNulla/store/Link/List'),
  loading: Loading,
});


const LinkItem = Loadable({
  loader: () => import('./projectNulla/store/Link/Item'),
  loading: Loading,
});

const PlanSubscriptions = Loadable({
  loader: () => import('./projectNulla/plan/Subscriptions/List'),
  loading: Loading,
});


const PurchaseList = Loadable({
  loader: () => import('./projectNulla/inventory/Purchase/List'),
  loading: Loading,
});

const PurchaseItem = Loadable({
  loader: () => import('./projectNulla/inventory/Purchase/Item'),
  loading: Loading,
});

const PurchaseProductsImport = Loadable({
  loader: () => import('./projectNulla/inventory/Purchase/Components/PurchaseProductsImport'),
  loading: Loading,
});

const SupplierList = Loadable({
  loader: () => import('./projectNulla/inventory/Supplier/List'),
  loading: Loading,
});

const SupplierItem = Loadable({
  loader: () => import('./projectNulla/inventory/Supplier/Item'),
  loading: Loading,
});

const WarehouseList = Loadable({
  loader: () => import('./projectNulla/inventory/Warehouse/List'),
  loading: Loading,
});

const WarehouseItem = Loadable({
  loader: () => import('./projectNulla/inventory/Warehouse/Item'),
  loading: Loading,
});

const routes = [
  { path: '/', exact: true, name: 'Home', component: DefaultLayout },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  
  { path: '/delivery/routes', exact: true, name: 'Routes', component: DeliveryRoutesList },
  { path: '/delivery/routes/create', exact: true, name: 'Route Create', component: DeliveryRoutesItem },
  { path: '/delivery/routes/update/:id', exact: true, name: 'Route Update', component: DeliveryRoutesItem },
  { path: '/delivery/delivery/:id', exact: true, name: 'Delivery', component: Delivery },
  /*________________________________project nulla routers___________________________________*/
  
  /*----->>>> catalogs*/
  { path: '/catalog', exact: true, name: 'Catalog', component: Dashboard },
  { path: '/catalog/messages', name: 'Messages', component: Messages },
  
  { path: '/catalog/information',  exact: true, name: 'Information', component: Information },
  { path: '/catalog/information/create', exact: true, name: 'Information Create', component: InformationCreate },
  { path: '/catalog/information/create/:id', name: '', component: InformationCreate },
  
  { path: '/catalog/review',  exact: true, name: 'Review', component: Reviews },
  { path: '/catalog/review/create', exact: true, name: 'Review Create', component: ReviewCreate },
  { path: '/catalog/review/update/:id', exact: true, name: 'Review Update', component: ReviewCreate },
  { path: '/catalog/reviews', name: 'Reviews', component: Reviews },
  
  { path: '/catalog/manufacturer', exact: true, name: 'Manufacturers', component: Manufacturers },
  { path: '/catalog/manufacturer/create', exact: true, name: 'Manufacturers create', component: ManufacturerCreate },
  { path: '/catalog/manufacturer/update/:id', name: '', component: ManufacturerCreate },

  { path: '/promotion/discount', exact: true, name: 'Discounts', component: Discounts },
  { path: '/promotion/discount/create', exact: true, name: 'Discount create', component: DiscountCreate },
  { path: '/promotion/discount/update/:id', name: 'Discount update', component: DiscountCreate },
  
  { path: '/promotion/promo_code', exact: true, name: 'Promo Codes', component: PromoCodes },
  { path: '/promotion/promo_code/create', exact: true, name: 'Promo Code create', component: PromoCodeCreate },
  { path: '/promotion/promo_code/update/:id', name: 'Promo Code update', component: PromoCodeCreate },

  { path: '/catalog/option', exact: true, name: 'Options', component: Options },
  { path: '/catalog/option/create', exact: true, name: 'Option Create', component: OptionCreate },
  { path: '/catalog/option/create/:id', name: '', component: OptionCreate }, // full
  
  { path: '/catalog/attribute_group', exact: true, name: 'Attribute Groups', component: AttributeGroups },
  { path: '/catalog/attribute_group/create', exact: true, name: 'Attribute Groups  Create', component: AttributeGroupsCreate },
  { path: '/catalog/attribute_group/create/:id', name: '', component: AttributeGroupsCreate },
  
  { path: '/catalog/attribute', exact: true, name: 'Attribute', component: Attribute },
  { path: '/catalog/attribute/create', exact: true, name: 'Attribute Create', component: AttributeCreate },
  { path: '/catalog/attribute/create/:id',  name: '', component: AttributeCreate }, 
  
  { path: '/catalog/filter', exact: true, name: 'Filters', component: Filters },
  { path: '/catalog/filter/create', exact: true, name: 'Filter Create', component: FilterCreate },
  { path: '/catalog/filter/create/:id',  name: '', component: FilterCreate }, // full
  
  { path: '/catalog/product', exact: true, name: 'Products', component: Products },
  { path: '/catalog/product/create' , exact: true, name: 'Products', component: ProductCreate },
  { path: '/catalog/product/create/:id', name: '', component: ProductCreate },
  
  { path: '/catalog/product/import', exact: true, name: 'Products Import', component: ProductsImport },
  { path: '/catalog/product/export', exact: true, name: 'Products Import', component: ProductsExport },

  { path: '/catalog/product_group', exact: true, name: 'Product Groups', component: ProductGroups },
  { path: '/catalog/product_group/create' , exact: true, name: 'Products', component: ProductGroupCreate },
  { path: '/catalog/product_group/create/:id', name: '', component: ProductGroupCreate },

  { path: '/catalog/brand', exact: true, name: 'Brands', component: BrandList },
  { path: '/catalog/brand/create' , exact: true, name: 'Brands', component: BrandCreate },
  { path: '/catalog/brand/update/:id', name: '', component: BrandCreate }, 
  
  { path: '/catalog/category', exact: true, name: 'Categories', component: Categories },
  { path: '/catalog/category/create', exact: true, name: 'Categories  Create', component: CategoryCreate },
  { path: '/catalog/category/create/:id', name: '', component: CategoryCreate },
  
  { path: '/catalog/recurring_profiles', name: 'Recurring Profiles', component: RecurringProfiles },
  
  
  
  /*----->>>> media*/
  
  { path: '/mediaFiles/downloads', exact: true, name: 'Downloads', component: Downloads },
  { path: '/tool/upload',exact: true,  name: 'Images', component: Image },
  { path: '/tool/video',exact: true,  name: 'Videos', component: Video },
  { path: '/tool/video/create', exact: true, name: 'Video create', component: VideoCreate },
  { path: '/tool/video/create/:id', exact: true, name: 'Video create', component: VideoCreate },
  { path: '/tool/icon',exact: true,  name: 'Icons', component: Icon },
  { path: '/tool/icon/create', exact: true, name: 'Icon create', component: IconCreate },
  { path: '/tool/icon/create/:id', exact: true, name: 'Icon create', component: IconCreate },
  { path: '/tool/upload/create', exact: true, name: 'Image create', component: ImageCreate },
  { path: '/tool/upload/create/:id', name: 'Image create', component: ImageCreate },
  { path: '/mediaFiles/slide', name: 'Slide', component: Slide },
  

  /*----->>>> store*/

  { path: '/store/store', exact: true, name: 'Stores', component: Stors },
  { path: '/store/store/create', exact: true, name: 'Store create', component: StoreCreate },
  { path: '/store/store/update/:id', name: 'Store update', component: StoreCreate },
  
  { path: '/store/franchise', exact: true, name: 'Franchise', component: Franchise },
  { path: '/store/franchise/create', exact: true, name: 'Franchise create', component: FranchiseCreate },
  { path: '/store/franchise/update/:id', name: 'Franchise update', component: FranchiseCreate },

  { path: '/store/link', exact: true, name: 'Link', component: Link },
  { path: '/store/link/create', exact: true, name: 'Link create', component: LinkItem },
  { path: '/store/link/update/:id', name: 'Link update', component: LinkItem },
  /*----->>>> sales*/
  /*----->>>> business*/
  { path: '/business/business', exact: true, name: 'Business', component: Business },
  { path: '/business/business/create', exact: true, name: 'Business create', component: BusinessItem },
  { path: '/business/business/update/:id', name: 'Business update', component: BusinessItem },

  { path: '/business/digital_card', exact: true, name: 'Digital Card', component: DigitalCard },
  { path: '/business/digital_card/create', exact: true, name: 'Digital Card create', component: DigitalCardCreate },
  { path: '/business/digital_card/update/:id', exact: true, name: 'Digital Card update', component: DigitalCardCreate },
  /*----->>>> promotion*/
  { path: '/promotion/promotion', exact: true, name: 'Promotion', component: Promotion },
  { path: '/promotion/promotion/create', exact: true, name: 'Promotion create', component: PromotionItem },
  { path: '/promotion/promotion/update/:id', name: 'Promotion update', component: PromotionItem },

  /*----->>>> plan*/
  { path: '/business/plan', exact: true, name: 'Plan', component: Plan },
  { path: '/business/plan/create', exact: true, name: 'Plan create', component: PlanItem },
  { path: '/business/plan/update/:id', name: 'Plan update', component: PlanItem },
  
  /*----->>>> plan*/
  { path: '/business/transaction', exact: true, name: 'Transaction', component: Transaction },

  // Calls
  { path: '/call/tickets', exact: true, name: 'Calls', component: Calls },
  { path: '/call/tickets/:id', exact: true, name: 'Call', component: Calls },

  // Sms
  { path: '/call/sms', exact: true, name: 'Sms', component: Sms },
  { path: '/call/sms/send', exact: true, name: 'Send Sms', component: SendSms },
  { path: '/call/sms/send/:id', exact: true, name: 'Send Sms', component: SendSms },

  // Sms
  { path: '/sms/history', exact: true, name: 'Sms', component: SmsList },
  { path: '/sms/plan', exact: true, name: 'Send Plan', component: SmsPlan },
  { path: '/sms/campaign/send', exact: true, name: 'Send Sms', component: SendBulkSms },
  { path: '/sms/campaign/send/:id', exact: true, name: 'Send Sms', component: SendBulkSms },
  { path: '/sms/send', exact: true, name: 'Send Sms', component: SendSms },
  { path: '/sms/send/:id', exact: true, name: 'Send Sms', component: SendSms },
  { path: '/sms/sms_group', exact: true, name: 'Send Group', component: SmsGroupList},
  { path: '/sms/sms_group/show/:id', exact: true, name: 'Send Group', component: SmsGroupItem},
  { path: '/sms/campaign', exact: true, name: 'Campaign', component: CampaignList},
  { path: '/sms/campaign/show/:id', exact: true, name: 'Campaign', component: CampaignItem},
  { path: '/sms/domain', exact: true, name: 'Domain', component: SmsDomainList },
  { path: '/sms/domain/create', exact: true, name: 'Domain Create', component: SmsDomainItem },
  { path: '/sms/domain/update/:id', exact: true, name: 'Domain Update', component: SmsDomainItem },
  { path: '/sms/phone', exact: true, name: 'Phone', component: SmsPhoneList },
  { path: '/sms/phone/create', exact: true, name: 'Phone Create', component: SmsPhoneItem },
  { path: '/sms/phone/update/:id', exact: true, name: 'Phone Update', component: SmsPhoneItem },


  // Call
  { path: '/call/history', exact: true, name: 'Call', component: CallList },
  { path: '/call/send', exact: true, name: 'Send Call', component: SendBulkCall },
  { path: '/call/send/:id', exact: true, name: 'Send Call', component: SendBulkCall },
  { path: '/call/call_group', exact: true, name: 'Send Group', component: CallGroupList},
  { path: '/call/call_group/show/:id', exact: true, name: 'Send Group', component: CallGroupItem},

  // Call
  { path: '/call/calls', exact: true, name: 'Call', component: Call },
  { path: '/call/calls/show/:id', exact: true, name: 'Call', component: CallItem },
  
  { path: '/sale',  exact: true, name: 'Sales', component: Dashboard },
  { path: '/sale/order', exact: true, name: 'Orders', component: Orders },
  { path: '/sale/order/create', exact: true, name: 'Order Create', component: OrderItem },
  { path: '/sale/order/update/:id', exact: true, name: 'Order Update', component: OrderItem },

  { path: '/sales/recurring_profiles', name: 'Recurring Profiles', component: SaleRecurringProfiles },
  { path: '/sales/returns', name: 'Returns', component: Returns },
  /*________________________________project nulla routers___________________________________*/
  

  /**Customer */
  { path: '/user/customer', exact: true, name: 'Customers', component: Customers },
  { path: '/user/customer/create', exact: true, name: 'Customers Create', component: CustomerCreate },
  { path: '/user/customer/update/:id', exact: true, name: 'Customers Update', component: CustomerCreate },

  /**Customer Group*/
  { path: '/user/customer_group', exact: true, name: 'Customers', component: CustomerGroup },
  { path: '/user/customer_group/create', exact: true, name: 'Customers Create', component: CustomerGroupCreate },
  { path: '/user/customer_group/update/:id', exact: true, name: 'Customers Update', component: CustomerGroupCreate },
  { path: '/user/customer_group/import', exact: true, name: 'Customers Import', component: CustomerImport },
  
  /**Customer */
  { path: '/user/chat', exact: true, name: 'Chat', component: Chat },
  // { path: '/customer/user/create', exact: true, name: 'User Create', component: CustomerCreate },

  /*----->>>> system*/
  { path: '/system/user', exact: true, name: 'Users', component: Users },
  { path: '/system/user/create', exact: true, name: 'User Create', component: UsersCreate },
  { path: '/system/user/update/:id', exact: true, name: 'User Update', component: UsersCreate },
  { path: '/system/franchise', exact: true, name: 'Franchise', component: Franchise },
  // { path: '/system/franchise/create', exact: true, name: 'Franchise Create', component: FranchiseCreate },
  // { path: '/system/franchise/create/:id', name: 'Franchise Create', component: FranchiseCreate },
  { path: '/localisation/language', exact: true, name: 'Languages', component: Languages },
  { path: '/localisation/language/create', exact: true, name: 'Languages Create', component: LanguagesCreate },
  { path: '/localisation/language/update/:id', exact: true, name: 'Languages Update', component: LanguagesCreate },
  { path: '/system/user_group', exact: true, name: 'User Groups', component: UserGroups },
  { path: '/system/user_group/create', exact: true, name: 'User Groups Create', component: UserGroupCreate },
  { path: '/system/user_group/update/:id', exact: true, name: 'User Groups Update', component: UserGroupCreate },
  { path: '/system/time_tracking', exact: true, name: 'Time Tracking', component: TimeTracking },
  { path: '/system/time_tracking/create', exact: true, name: 'Time Tracking Create', component: TimeTrackingCreate },
  { path: '/system/time_tracking/update/:id', exact: true, name: 'Time Trackin Update', component: TimeTrackingCreate },
  { path: '/business/equipment', exact: true, name: 'Equipment', component: Equipment },
  { path: '/business/equipment/create', exact: true, name: 'Equipment Create', component: EquipmentCreate },
  { path: '/business/equipment/update/:id', exact: true, name: 'Equipment Update', component: EquipmentCreate },
  { path: '/localisation/currency', exact: true, name: 'Currency', component: Currency },
  { path: '/localisation/currency/create', exact: true, name: 'Currency Create', component: CurrencyCreate },
  { path: '/localisation/currency/update/:id', exact: true, name: 'Currency Update', component: CurrencyCreate },
  
  
  
  { path: '/theme', exact: true, name: 'Theme', component: Colors },
  { path: '/theme/colors', name: 'Colors', component: Colors },
  { path: '/theme/typography', name: 'Typography', component: Typography },
  { path: '/base', exact: true, name: 'Base', component: Cards },
  { path: '/base/cards', name: 'Cards', component: Cards },
  { path: '/base/forms', name: 'Forms', component: Forms },
  { path: '/base/switches', name: 'Switches', component: Switches },
  { path: '/base/tables', name: 'Tables', component: Tables },
  { path: '/base/tabs', name: 'Tabs', component: Tabs },
  { path: '/base/breadcrumbs', name: 'Breadcrumbs', component: Breadcrumbs },
  { path: '/base/carousels', name: 'Carousel', component: Carousels },
  { path: '/base/collapses', name: 'Collapse', component: Collapses },
  { path: '/base/dropdowns', name: 'Dropdowns', component: Dropdowns },
  { path: '/base/jumbotrons', name: 'Jumbotrons', component: Jumbotrons },
  { path: '/base/list-groups', name: 'List Groups', component: ListGroups },
  { path: '/base/navbars', name: 'Navbars', component: Navbars },
  { path: '/base/navs', name: 'Navs', component: Navs },
  { path: '/base/paginations', name: 'Paginations', component: Paginations },
  { path: '/base/popovers', name: 'Popovers', component: Popovers },
  { path: '/base/progress-bar', name: 'Progress Bar', component: ProgressBar },
  { path: '/base/tooltips', name: 'Tooltips', component: Tooltips },
  { path: '/buttons', exact: true, name: 'Buttons', component: Buttons },
  { path: '/buttons/buttons', name: 'Buttons', component: Buttons },
  { path: '/buttons/button-dropdowns', name: 'Button Dropdowns', component: ButtonDropdowns },
  { path: '/buttons/button-groups', name: 'Button Groups', component: ButtonGroups },
  { path: '/buttons/brand-buttons', name: 'Brand Buttons', component: BrandButtons },
  { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons },
  { path: '/icons/flags', name: 'Flags', component: Flags },
  { path: '/icons/font-awesome', name: 'Font Awesome', component: FontAwesome },
  { path: '/icons/simple-line-icons', name: 'Simple Line Icons', component: SimpleLineIcons },
  { path: '/notifications', exact: true, name: 'Notifications', component: Alerts },
  { path: '/notifications/alerts', name: 'Alerts', component: Alerts },
  { path: '/notifications/badges', name: 'Badges', component: Badges },
  { path: '/notifications/modals', name: 'Modals', component: Modals },
  { path: '/widgets', name: 'Widgets', component: Widgets },
  { path: '/charts', name: 'Charts', component: Charts },
  { path: '/users', exact: true,  name: 'Users', component: Users },
  { path: '/users/:id', exact: true, name: 'User Details', component: User },
  { path: '/system/notification/type', exact: true, name: 'Notification Type', component: NotificationType },
  { path: '/system/notification/type/create', exact: true, name: 'Notification Type Create', component: NotificationTypeItem },
  { path: '/system/notification/type/update/:id', exact: true, name: 'Notification Type Update', component: NotificationTypeItem },
  { path: '/system/notification/sound', exact: true, name: 'Notification Sound', component: NotificationSound },
  { path: '/system/notification/sound/create', exact: true, name: 'Notification Sound Create', component: NotificationSoundItem },
  { path: '/system/notification/sound/update/:id', exact: true, name: 'Notification Sound Update', component: NotificationSoundItem },
  { path: '/system/notification/settings', exact: true, name: 'Notification Settings', component: Notification },
  { path: '/plan/subscription', exact: true, name: 'Subscriptions', component: PlanSubscriptions },
  { path: '/inventory/purchase', exact: true, name: 'Purchase List', component: PurchaseList },
  { path: '/inventory/purchase/create', exact: true, name: 'Purchase Create', component: PurchaseItem },
  { path: '/inventory/purchase/update/:id', exact: true, name: 'Purchase Update', component: PurchaseItem },
  { path: '/inventory/purchase/import', exact: true, name: 'Purchase Products Import', component: PurchaseProductsImport },
  { path: '/inventory/supplier', exact: true, name: 'Supplier List', component: SupplierList },
  { path: '/inventory/supplier/create', exact: true, name: 'Supplier Create', component: SupplierItem },
  { path: '/inventory/supplier/update/:id', exact: true, name: 'Supplier Update', component: SupplierItem },
  { path: '/inventory/warehouse', exact: true, name: 'Warehouse List', component: WarehouseList },
  { path: '/inventory/warehouse/create', exact: true, name: 'Warehouse Create', component: WarehouseItem },
  { path: '/inventory/warehouse/update/:id', exact: true, name: 'Warehouse Update', component: WarehouseItem },
];

export default routes;
