const GET_BRAND = 'GET_BRAND';
const GET_DELETED_BRAND = 'GET_DELETED_BRAND';
const SELECT_BRAND_ITEMS = 'SELECT_BRAND_ITEMS';
const GET_BRAND_ITEM = 'GET_BRAND_ITEM';
const GET_PAG_NOT_REMOVED_BRANDS = 'GET_PAG_NOT_REMOVED_BRANDS';
const GET_PAG_REMOVED_BRANDS = 'GET_PAG_REMOVED_BRANDS';
const DELETE_BRAND_ITEMS = 'DELETE_BRAND_ITEMS';
const RECOVER_BRAND_ITEMS = 'RECOVER_BRAND_ITEMS';
const ARCHIVE_BRAND_ITEMS = 'ARCHIVE_BRAND_ITEMS';
const BULK_RECOVER_BRAND_ITEMS = 'BULK_RECOVER_BRAND_ITEMS';
const NEW_BRAND_FULFILLED = 'NEW_BRAND_FULFILLED';
const NEW_BRAND_REJECTED = 'NEW_BRAND_REJECTED';
const SAVE_BRAND_P = 'SAVE_BRAND_P';
const SAVE_BRAND_FULFILLED = 'SAVE_BRAND_FULFILLED';
const SAVE_BRAND_REJECTED = 'SAVE_BRAND_REJECTED';
const FETCH_BRAND_FULFILLED = 'FETCH_BRAND_FULFILLED';
const FETCH_BRAND_PENDING = 'FETCH_BRAND_PENDING';
const UPDATE_BRAND_P = 'UPDATE_BRAND_P';
const UPDATE_BRAND_FULFILLED = 'UPDATE_BRAND_FULFILLED';
const UPDATE_BRAND_R = 'UPDATE_BRAND_R';
const LANG_TAB = 'LANG_TAB';
const DESTROY_BRAND = 'DESTROY_BRAND';
const BULK_DESTROY_BRAND_ITEMS = 'BULK_DESTROY_BRAND_ITEMS'
const BRAND_TAB_PANEL_CHANGE = 'BRAND_TAB_PANEL_CHANGE'
const BRAND_FILTER_IS_OPEN = 'BRAND_FILTER_IS_OPEN'
const BRAND_DATA_FILTER = 'BRAND_DATA_FILTER'
const BRAND_DATA_FILTER_SEARCH = 'BRAND_DATA_FILTER_SEARCH'
const BRAND_DATA_MIN_MAX_F = 'BRAND_DATA_MIN_MAX_F'
const BRAND_SELECT_FILTER_TYPES = 'BRAND_SELECT_FILTER_TYPES'
const BRAND_DATA_FILTER_SEARCH_RESET = 'BRAND_DATA_FILTER_SEARCH_RESET'
const BRAND_DATA_FILTER_ALL_SEARCH_RESET = 'BRAND_DATA_FILTER_ALL_SEARCH_RESET'
const FETCH_BRAND_SEE_ALL = 'FETCH_BRAND_SEE_ALL'
const GET_PAG_ALL_BRANDS = 'GET_PAG_ALL_BRANDS'

export {
  GET_BRAND,
  GET_DELETED_BRAND,
  SELECT_BRAND_ITEMS,
  GET_BRAND_ITEM,
  GET_PAG_NOT_REMOVED_BRANDS,
  GET_PAG_REMOVED_BRANDS,
  DELETE_BRAND_ITEMS,
  RECOVER_BRAND_ITEMS,
  ARCHIVE_BRAND_ITEMS,
  BULK_RECOVER_BRAND_ITEMS,
  NEW_BRAND_FULFILLED,
  NEW_BRAND_REJECTED,
  SAVE_BRAND_P,
  SAVE_BRAND_FULFILLED,
  SAVE_BRAND_REJECTED,
  FETCH_BRAND_FULFILLED,
  FETCH_BRAND_PENDING,
  UPDATE_BRAND_P,
  UPDATE_BRAND_FULFILLED,
  UPDATE_BRAND_R,
  LANG_TAB,
  DESTROY_BRAND,
  BULK_DESTROY_BRAND_ITEMS,
  BRAND_TAB_PANEL_CHANGE,
  BRAND_FILTER_IS_OPEN,
  BRAND_DATA_FILTER,
  BRAND_DATA_FILTER_SEARCH,
  BRAND_DATA_MIN_MAX_F,
  BRAND_SELECT_FILTER_TYPES,
  BRAND_DATA_FILTER_SEARCH_RESET,
  BRAND_DATA_FILTER_ALL_SEARCH_RESET,
  FETCH_BRAND_SEE_ALL,
  GET_PAG_ALL_BRANDS
}