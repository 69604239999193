import React, { useState, useEffect } from 'react';

const NoInternetAlert = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        // Cleanup event listeners on unmount
        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    return (
        <>
            {!isOnline && (
                <div style={alertStyle}>
                    ⚠️ No Internet Connection. Please check your network.
                </div>
            )}
        </>
    );
};

// Inline styling for the alert
const alertStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: 'red',
    color: 'white',
    padding: '10px',
    textAlign: 'center',
    zIndex: 1000,
};

export default NoInternetAlert;